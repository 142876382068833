import { ValueFileUploader } from 'utils/file-uploader';
import * as yup from 'yup';
const API = 'UserPatientProfileDietDiaries';

export const API_USER_PATIENT_PROFILE_DIET_DIARIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'>) =>
    `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'>) =>
    `${API}/Delete/${data.id}`,
};

export const schemaDietDiary = yup.object({
  userPatientProfileID: yup.string().required('rule-required').default(''),
  dietDiaryTypeID: yup.string().required('rule-required').default(''),
  entryDate: yup.string().required('rule-required').default(new Date().toISOString()),
  description: yup.string().notRequired().nullable().default(''),
  reply: yup.string().notRequired().nullable().default(''),
  userEmployeeProfileID: yup
    .string()
    .when('reply', {
      is: (value: string) => !!value,
      then: (schema) => schema.required('rule-required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    })
    .default(''),
  replyDate: yup
    .string()
    .when('reply', {
      is: (value: string) => !!value,
      then: (schema) => schema.required('rule-required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    })
    .default(''),
  isViewed: yup.boolean().default(false),
  files: yup
    .array()
    .of(
      yup.object({
        fileURL: yup.string().nullable().notRequired(),
        file: yup.mixed(),
      }),
    )
    .max(8, 'rule-max')
    .default([]),
});

export interface UserPatientProfileDietDiary
  extends Components.Schemas.UserPatientProfileDietDiary {}

export type DietDiaryFileValue = { fileURL: string; file: ValueFileUploader };
export interface UserPatientProfileDietDiaryGetOutput
  extends Pick<
    Required<UserPatientProfileDietDiary>,
    | 'id'
    | 'userPatientProfileID'
    | 'userEmployeeProfileID'
    | 'dietDiaryTypeID'
    | 'entryDate'
    | 'description'
    | 'reply'
    | 'replyDate'
    | 'isViewed'
  > {
  fileURLs: string[];
  files: DietDiaryFileValue[];
}

export interface UserPatientProfileDietDiaryPostInput
  extends Components.Schemas.UserPatientProfileDietDiary {
  files: DietDiaryFileValue[];
}
export interface UserPatientProfileDietDiaryPatchInput
  extends Components.Schemas.UserPatientProfileDietDiary {
  id: string;
  files: DietDiaryFileValue[];
}
export interface UserPatientProfileDietDiaryDeleteInput
  extends Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'> {}
