import { ChangeLogConfig } from 'modules/change-log';

import { schemaTodoTaskUpdate, TodoTask, ToDoTaskUpdateInput } from './models';
import { InferType } from 'yup';
import { dateFormat, isDateLike } from 'utils/dates';
import { UserEmployeeProfile } from '../user-employee-profiles';
import { PatchPartial } from '../../utils/types';

export type TodoTaskDefinition = TodoTask & {
  employees: Pick<UserEmployeeProfile, 'fullName'>[];
};

type Config = ChangeLogConfig<
  PatchPartial<ToDoTaskUpdateInput, 'id'>,
  PatchPartial<ToDoTaskUpdateInput, 'id'>,
  TodoTaskDefinition
>;

export const logConfig: Record<keyof InferType<typeof schemaTodoTaskUpdate>, Config> = {
  toDoTaskCategoryID: {
    select: 'new { toDoTaskCategory.title } as toDoTaskCategory',
    getValue: (res) => String(res.toDoTaskCategory?.title),
  },
  userPatientProfileSessionID: {
    select: 'new { userPatientProfileSession.entryDate } as userPatientProfileSession',
    getValue: (res) =>
      isDateLike(res.userPatientProfileSession?.entryDate)
        ? dateFormat(res.userPatientProfileSession?.entryDate)
        : String(res.userPatientProfileSession?.entryDate),
  },
  entryDate: {
    select: 'entryDate',
    getValue: (res) =>
      isDateLike(res.entryDate) ? dateFormat(res.entryDate) : String(res.entryDate),
  },
  message: {
    select: 'message',
    getValue: (res) => String(res.message),
  },
  userEmployeeProfileIDs: {
    select:
      'userEmployeeProfileToDoTasks.OrderBy(s=>s.userEmployeeProfile.firstName).Select(s => new { s.userEmployeeProfile.fullName }) as employees',
    getValue: (res) => res.employees.map((e) => e.fullName).join(', '),
  },
};
