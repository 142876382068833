import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { ButtonBase, ButtonBaseProps, Typography } from '@material-ui/core';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { getAge, isTeenager } from 'utils/dates';
import { TemplatePatientFullName } from '../template-patient-full-name';
import ReplyIcon from '@material-ui/icons/Reply';
import { makeFullName } from 'utils/app-helpers';

interface Patient {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  dateOfBirth: string | null;
  isActive: boolean | undefined;
}

export interface TemplatePatientProps extends ButtonBaseProps {
  patient: Partial<Patient>;
  isTLC: boolean;
  onHold: boolean;
  doNotProlongTreatment: boolean | undefined;
  slowIncreaseWeight: boolean | undefined;
  changeDosageByDoctorApproval: boolean | undefined;
}

export const TemplatePatient = memo<TemplatePatientProps>(
  ({
    className,
    patient,
    doNotProlongTreatment,
    slowIncreaseWeight,
    isTLC,
    changeDosageByDoctorApproval,
    onHold,
    ...rest
  }) => {
    const { t } = useTranslate();
    const age = useMemo(() => {
      return getAge(patient.dateOfBirth);
    }, [patient.dateOfBirth]);

    const isChild = useMemo(() => {
      return isTeenager(patient.dateOfBirth);
    }, [patient.dateOfBirth]);

    const fullName = useMemo(() => {
      return makeFullName(patient);
    }, [patient]);

    const isAllowToViewPatients = usePermissions('isAllowToEditPatients');

    const isClickable = !!(isAllowToViewPatients && rest.onClick);

    return (
      <ButtonBase
        className={clsx(style.root, className, {
          [style.child]: isChild,
        })}
        disableRipple={!isClickable}
        {...rest}
        onClick={isClickable ? rest.onClick : undefined}
      >
        <TemplatePatientFullName
          isActive={patient.isActive}
          isTLC={isTLC}
          onHold={onHold}
          color={'inherit'}
          className={clsx(style.name)}
          doNotProlongTreatment={doNotProlongTreatment}
          slowIncreaseWeight={slowIncreaseWeight}
          dateOfBirth={patient.dateOfBirth}
          changeDosageByDoctorApproval={changeDosageByDoctorApproval}
        >
          {fullName}
        </TemplatePatientFullName>
        <Typography color={'inherit'} className={style.age}>
          {t('age')}: {age}
        </Typography>
        {isClickable && <ReplyIcon className={style.icon} />}
      </ButtonBase>
    );
  },
);
