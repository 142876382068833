import React, { useCallback, useMemo } from 'react';
import {
  apiUserPatientProfile,
  PREFER_PHARMACY_TYPE,
  UserPatientProfileEditGeneralInput,
} from 'services/user-patient-profile';
import style from './index.module.scss';
import { RowField } from 'components/row-field';
import {
  useSourceBmiSummaries,
  useSourceCities,
  useSourceDiagnosisTypes,
  useSourceDietitians,
  useSourceGenders,
  useSourceProfilesSupport,
} from 'components/hooks';

import { APP_FORMAT_DATE } from 'configs/const';
import { Controller, useFormContext, ControllerRenderProps } from 'react-hook-form';
import { AppPhoneInput } from 'components/app-phone-input';
import { composeFunctions } from 'utils';
import { ServicePushNotifications } from 'services/push-notifications';
import { useEffectError, useFieldProps } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { AppInput } from 'components/app-input';
import { dateFormat, DateValue, getAge, isDateLike, isTeenager } from 'utils/dates';
import { calcBmi } from 'utils/app-helpers';
import { Left } from 'utils/types';
import { ButtonFieldMember } from '../button-field-member';
import { AppDatePicker } from 'components/app-date-picker';
import { transformToPatientIdNumber } from 'utils/transformers';
import { SelectEmployee } from 'components/select-employee';
import { usePermissions } from 'hooks/use-permissions';
import { AppSelect } from 'components/app-select';
import { AppCheckbox } from 'components/app-checkbox';
import { AppHeightInput } from 'components/app-height-input';
import {
  useFactoryChangeInput,
  useFactoryChangeSelect,
  usePatientData,
} from 'components/dialog-patient/hooks';
import { AppIdNumberInput } from 'components/app-id-number-input';
import { AppInputLtr } from 'components/app-input-ltr';
import { getPatientFieldsMaxLength, PatientFormModel } from '../../helpers';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogPatientOnHold } from '../dialog-patient-on-hold';
import { AppPassportNumberInput } from 'components/app-pasport-number-input';
import { DialogPatientDeactivate } from '../dialog-deactivate';
import { patientEmitter } from '../../emitter';
import { withConfirmBefore } from 'hocs/with-confirm';
import { enumToArray } from '../../../../utils/other';

const DialogPatientDeactivateWithConfirm = withConfirmBefore(DialogPatientDeactivate);

const useMakePatientUnHoldMutation = apiUserPatientProfile.useMakePatientUnHoldMutation;
const useActivatePatientMutation = apiUserPatientProfile.useActivatePatientMutation;

type FormModel = Left<PatientFormModel>;
const getMaxLength = getPatientFieldsMaxLength;

const getDateValue = (value: any) => {
  if (isDateLike(value)) {
    return dateFormat(value, APP_FORMAT_DATE);
  }
  return 'null';
};

interface Props {
  showDietitian: boolean;
  isLoading: boolean;
  isNew: boolean;
}

export const Form: React.FC<Props> = ({ showDietitian, isLoading, isNew }) => {
  const { patient, onUpdateDebounce } = usePatientData();
  const userPatientProfileID = patient?.appIdentityUserID ?? null;

  const { tp, t } = useTranslate();

  const { control, watch, errors, getValues, setValue, trigger } = useFormContext<FormModel>();
  const userEmployeeProfileSupportPrimaryID = watch('userEmployeeProfileSupportPrimaryID');
  const userEmployeeProfileSupportSecondaryID = watch('userEmployeeProfileSupportSecondaryID');

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const sourceProfilesSupport = useSourceProfilesSupport();

  const primarySupportOptions = useMemo(() => {
    return sourceProfilesSupport.data.filter(
      (item) => item.id !== userEmployeeProfileSupportSecondaryID,
    );
  }, [sourceProfilesSupport, userEmployeeProfileSupportSecondaryID]);

  const secondarySupportOptions = useMemo(() => {
    return sourceProfilesSupport.data.filter(
      (item) => item.id !== userEmployeeProfileSupportPrimaryID,
    );
  }, [sourceProfilesSupport, userEmployeeProfileSupportPrimaryID]);

  const sourceDietitians = useSourceDietitians();

  const sourceDiagnosisTypes = useSourceDiagnosisTypes();
  const sourceBmiSummaries = useSourceBmiSummaries();

  const onChangeMustFillHealthSurvey = useCallback(
    (checked: boolean) => {
      if (checked && userPatientProfileID) {
        ServicePushNotifications.sendToPatient({
          type: 'HEALTH_SURVEY',
          userPatientProfileID,
          title: t('health-survey'),
          message: t('health-survey-message'),
        });
      }
    },
    [userPatientProfileID, t],
  );

  const dateOfBirth = watch('dateOfBirth');
  const age = useMemo(() => {
    return getAge(dateOfBirth) || '--';
  }, [dateOfBirth]);

  const weight = watch('weight') || 0;
  const height = watch('height') || 0;
  const bmi = useMemo(() => {
    return calcBmi(weight, height);
  }, [weight, height]);

  const onBlurIdNumber = useCallback(() => {
    if (!isNew) return;

    const value = getValues('idNumber');

    if (!value) return;

    const newValue = transformToPatientIdNumber(value);

    setValue('idNumber', newValue);
  }, [getValues, setValue, isNew]);

  const sourceGenders = useSourceGenders();
  const sourceCities = useSourceCities();

  const factoryChangeDate = useCallback(
    (field: string, name: string) => {
      return () => {
        if (!onUpdateDebounce) return;

        let key = field as keyof UserPatientProfileEditGeneralInput;
        const value = getValues(key);
        const oldValue = patient ? patient[key] : null;

        const description = {
          oldValue: getDateValue(oldValue),
          newValue: getDateValue(value),
          name,
        };

        onUpdateDebounce({ [field]: value }, description);
      };
    },
    [onUpdateDebounce, patient, getValues],
  );
  const factoryProps = {
    onUpdate: onUpdateDebounce,
    initData: patient,
    getValues,
    validate: trigger,
  };

  const factoryChangeInput = useFactoryChangeInput(factoryProps);
  const factoryChangeSelect = useFactoryChangeSelect(factoryProps);

  const renderInput = useCallback(
    (renderProps: ControllerRenderProps) => {
      const { label, ...rest } = getFieldProps(renderProps);
      const maxLength = getMaxLength(renderProps.name as keyof FormModel);
      return (
        <RowField label={label}>
          <AppInput
            {...rest}
            inputProps={{
              maxLength,
            }}
            disabled={isLoading}
            disableClearable={true}
            onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
          />
        </RowField>
      );
    },
    [isLoading, factoryChangeInput, getFieldProps],
  );

  const makeLabelWeight = useCallback(
    (label: string) => {
      return [label, t('unit-kg')].join(' ');
    },
    [t],
  );

  const leadWeight = patient?.leadWeight;
  const roleTelemarketing = usePermissions('roleTelemarketing');
  const roleDoctor = usePermissions('roleDoctor');
  const isAllowToEditPermissions = usePermissions('isAllowToEditPermissions');
  const isAllowToEditCommercials = usePermissions('isAllowToEditCommercials');
  const isAllowToInactiveOrOnHoldPatient = usePermissions('isAllowToInactiveOrOnHoldPatient');

  const isShowLeadWeight = useMemo(() => {
    return [roleTelemarketing, roleDoctor, isAllowToEditPermissions, isAllowToEditCommercials].some(
      Boolean,
    );
  }, [roleTelemarketing, roleDoctor, isAllowToEditPermissions, isAllowToEditCommercials]);

  const [makeUnHold, resultMakeUnHold] = useMakePatientUnHoldMutation();
  useEffectError(resultMakeUnHold.error);

  const stateOnHoldDialog = useOpen();
  const onHoldCancel = useCallback(() => {
    stateOnHoldDialog.handleClose();
    setValue('onHold', false);
  }, [stateOnHoldDialog, setValue]);
  const onHoldDone = useCallback(
    (formData: { onHoldReason: string; onHoldEndDate: string }) => {
      stateOnHoldDialog.handleClose();
      setValue('onHold', true);
      setValue('onHoldReason', formData.onHoldReason);
      setValue('onHoldEndDate', formData.onHoldEndDate);
    },
    [stateOnHoldDialog, setValue],
  );
  const onHoldChange = useCallback(
    async (checked: boolean) => {
      if (checked) {
        return stateOnHoldDialog.handleOpen();
      }
      if (!userPatientProfileID) return;
      await makeUnHold({ userPatientProfileID });
    },
    [stateOnHoldDialog, userPatientProfileID, makeUnHold],
  );

  const [activate, resultActivate] = useActivatePatientMutation();
  useEffectError(resultActivate.error);
  const stateDeactivateDialog = useOpen();

  const onChangeActive = (checked: boolean) => {
    if (!userPatientProfileID) return;
    if (!checked) {
      return stateDeactivateDialog.handleOpen();
    }

    activate(userPatientProfileID);
    setValue('isActive', true);
  };
  const onDeactivateConfirm = useCallback(() => {
    setValue('isActive', false);
  }, [setValue]);
  const onDeactivateDecline = useCallback(() => {
    setValue('isActive', true);
    stateDeactivateDialog.handleClose();
  }, [setValue, stateDeactivateDialog]);

  const onDeactivateSuccess = () => {
    if (!userPatientProfileID) {
      return;
    }
    patientEmitter.emit('refresh-meetings', { patientID: userPatientProfileID });
  };

  const onChangeDateOfBirth = (date: DateValue) => {
    if (!isDateLike(date)) return;
    if (!isTeenager(date)) return;

    const name = 'isTLC';
    setValue(name, true);
    factoryChangeInput(name, t('tlc'))();
  };

  const pharmacyTypeOptions = useMemo(() => {
    return enumToArray(PREFER_PHARMACY_TYPE).map(({ id }) => {
      return { id, title: t(id) };
    });
  }, [t]);

  return (
    <>
      <div className={style.rowWrapper}>
        <div className={style.box}>
          <Controller control={control} name={'firstName'} render={renderInput} />
          <Controller control={control} name={'lastName'} render={renderInput} />
          <Controller control={control} name={'nameEng'} render={renderInput} />
          <Controller
            control={control}
            name={'idNumber'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const watchChange = factoryChangeInput(rest.name, label);
              return (
                <RowField label={label}>
                  <AppIdNumberInput
                    {...rest}
                    onBlur={composeFunctions(rest.onBlur, onBlurIdNumber, watchChange)}
                    onChange={composeFunctions(rest.onChange, watchChange)}
                    disabled={isLoading}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'passportNumber'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const watchChange = factoryChangeInput(rest.name, label);
              return (
                <RowField label={label}>
                  <AppPassportNumberInput
                    {...rest}
                    onBlur={composeFunctions(rest.onBlur, watchChange)}
                    onChange={composeFunctions(rest.onChange, watchChange)}
                    disabled={isLoading}
                  />
                </RowField>
              );
            }}
          />

          <Controller
            name={'dateOfBirth'}
            control={control}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const changeDate = composeFunctions(
                factoryChangeDate('dateOfBirth', label),
                onChangeDateOfBirth,
              );

              return (
                <RowField label={label}>
                  <AppDatePicker
                    {...rest}
                    format={APP_FORMAT_DATE}
                    onAccept={changeDate}
                    onBlur={composeFunctions(rest.onBlur, changeDate)}
                  />
                </RowField>
              );
            }}
          />

          <RowField label={t('age')}>
            <AppInput value={age} InputProps={{ readOnly: true }} disableClearable />
          </RowField>
          <Controller
            control={control}
            name={'genderID'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppSelect
                    {...rest}
                    options={sourceGenders.data}
                    loading={sourceGenders.loading}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeSelect(rest.name, label, sourceGenders.map),
                    )}
                    disabled={isLoading}
                    disableClearable
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'cityID'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppSelect
                    {...rest}
                    options={sourceCities.data}
                    loading={sourceCities.loading}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeSelect(rest.name, label, sourceCities.map),
                    )}
                    disabled={isLoading}
                    disableClearable
                  />
                </RowField>
              );
            }}
          />
          <Controller control={control} name={'address'} render={renderInput} />
          <Controller
            control={control}
            name={'isTLC'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const visibleLabel = t('tlc');
              return (
                <RowField label={visibleLabel}>
                  <AppCheckbox
                    {...rest}
                    disabled={isLoading}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeInput(rest.name, visibleLabel),
                    )}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'doNotProlongTreatment'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppCheckbox
                    {...rest}
                    disabled={isLoading}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'slowIncreaseWeight'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppCheckbox
                    {...rest}
                    disabled={isLoading}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'changeDosageByDoctorApproval'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppCheckbox
                    {...rest}
                    disabled={isLoading}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          {isAllowToInactiveOrOnHoldPatient && (
            <Controller
              control={control}
              name={'isActive'}
              render={(renderProps) => {
                const { label, ...rest } = getFieldProps(renderProps);
                return (
                  <RowField label={label}>
                    <AppCheckbox {...rest} disabled={isLoading} onChange={onChangeActive} />
                  </RowField>
                );
              }}
            />
          )}
          <RowField label={t('family-member')}>
            <ButtonFieldMember debounce={true} />
          </RowField>
        </div>
        <div className={style.box}>
          <Controller
            name={'mobilePhone'}
            control={control}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppPhoneInput
                    {...rest}
                    label={null}
                    disabled={isLoading}
                    onBlur={composeFunctions(rest.onBlur, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />

          <Controller
            control={control}
            name={'email'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const maxLength = getMaxLength(renderProps.name as keyof FormModel);

              return (
                <RowField label={label}>
                  <AppInputLtr
                    {...rest}
                    inputProps={{
                      maxLength,
                    }}
                    type={'email'}
                    disabled={isLoading}
                    disableClearable={true}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'occupation'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const maxLength = getMaxLength(renderProps.name as keyof FormModel);
              return (
                <RowField label={label}>
                  <AppInput
                    {...rest}
                    inputProps={{
                      maxLength,
                    }}
                    disabled={isLoading}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />

          <Controller
            control={control}
            name={'weight'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);

              const visibleLabel = makeLabelWeight(t('start-weight'));
              return (
                <RowField label={visibleLabel}>
                  <AppInput
                    {...rest}
                    type={'number'}
                    disabled={isLoading}
                    disableClearable={true}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeInput(rest.name, visibleLabel),
                    )}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'maintenanceWeight'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const visibleLabel = makeLabelWeight(label);
              return (
                <RowField label={visibleLabel}>
                  <AppInput
                    {...rest}
                    type={'number'}
                    disabled={isLoading}
                    disableClearable={true}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeInput(rest.name, visibleLabel),
                    )}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'height'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const visibleLabel = [t('height'), t('unit-cm')].join(' ');
              return (
                <RowField label={visibleLabel}>
                  <AppHeightInput
                    {...rest}
                    disabled={isLoading}
                    disableClearable={true}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeInput(rest.name, visibleLabel),
                    )}
                  />
                </RowField>
              );
            }}
          />
          <RowField label={t('bmi')}>
            <AppInput value={bmi} InputProps={{ readOnly: true }} disableClearable />
          </RowField>
          <Controller
            control={control}
            name={'diagnosisTypeID'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppSelect
                    {...rest}
                    options={sourceDiagnosisTypes.data}
                    loading={sourceDiagnosisTypes.loading}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeSelect(rest.name, label, sourceDiagnosisTypes.map),
                    )}
                    disabled={isLoading}
                    disableClearable
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'bmiSummaryID'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppSelect
                    {...rest}
                    options={sourceBmiSummaries.data}
                    loading={sourceBmiSummaries.loading}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeSelect(rest.name, label, sourceBmiSummaries.map),
                    )}
                    disabled={isLoading}
                    disableClearable
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'medicalRemarks'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const maxLength = getMaxLength(renderProps.name as keyof FormModel);
              return (
                <RowField label={label}>
                  <AppInput
                    {...rest}
                    inputProps={{
                      maxLength,
                    }}
                    disabled={isLoading}
                    disableClearable={true}
                    multiline
                    minRows={4}
                    maxRows={8}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          {userPatientProfileID && (
            <Controller
              control={control}
              name={'mustFillHealthSurvey'}
              render={(renderProps) => {
                const { label, ...rest } = getFieldProps(renderProps);
                return (
                  <RowField label={label}>
                    <AppCheckbox
                      {...rest}
                      disabled={isLoading}
                      onChange={composeFunctions(
                        rest.onChange,
                        factoryChangeInput(rest.name, label),
                        onChangeMustFillHealthSurvey,
                      )}
                    />
                  </RowField>
                );
              }}
            />
          )}
          <Controller
            control={control}
            name={'doNotAllowEndOfTreatmentLetter'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppCheckbox
                    {...rest}
                    disabled={isLoading}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
        </div>
        <div className={style.box}>
          <Controller
            control={control}
            name={'userEmployeeProfileSupportPrimaryID'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <SelectEmployee
                    {...rest}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeSelect(rest.name, label, sourceProfilesSupport.map),
                    )}
                    options={primarySupportOptions}
                    loading={sourceProfilesSupport.loading}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'userEmployeeProfileSupportSecondaryID'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <SelectEmployee
                    {...rest}
                    onChange={composeFunctions(
                      rest.onChange,
                      factoryChangeSelect(rest.name, label, sourceProfilesSupport.map),
                    )}
                    options={secondarySupportOptions}
                    loading={sourceProfilesSupport.loading}
                  />
                </RowField>
              );
            }}
          />
          {showDietitian && (
            <Controller
              control={control}
              name={'userEmployeeProfileDietitianID'}
              render={(renderProps) => {
                const { label, ...rest } = getFieldProps(renderProps);
                return (
                  <RowField label={label}>
                    <SelectEmployee
                      {...rest}
                      onChange={composeFunctions(
                        rest.onChange,
                        factoryChangeSelect(rest.name, label, sourceDietitians.map),
                      )}
                      options={sourceDietitians.data}
                      loading={sourceDietitians.loading}
                    />
                  </RowField>
                );
              }}
            />
          )}
          <Controller
            control={control}
            name={'preferedPharmacyType'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              return (
                <RowField label={label}>
                  <AppSelect
                    {...rest}
                    options={pharmacyTypeOptions}
                    disabled={isLoading}
                    disableClearable={true}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'remarks'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const maxLength = getMaxLength(renderProps.name as keyof FormModel);
              return (
                <RowField label={label}>
                  <AppInput
                    {...rest}
                    inputProps={{
                      maxLength,
                    }}
                    disabled={isLoading}
                    disableClearable={true}
                    multiline
                    minRows={4}
                    maxRows={8}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          <Controller
            control={control}
            name={'shortRemark'}
            render={(renderProps) => {
              const { label, ...rest } = getFieldProps(renderProps);
              const maxLength = getMaxLength(renderProps.name as keyof FormModel);
              return (
                <RowField label={label}>
                  <AppInput
                    {...rest}
                    inputProps={{
                      maxLength,
                    }}
                    disabled={isLoading}
                    disableClearable={true}
                    onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                  />
                </RowField>
              );
            }}
          />
          {isShowLeadWeight && (
            <RowField label={makeLabelWeight(t('lead-weight'))}>
              <AppInput
                value={leadWeight}
                type={'number'}
                disabled={isLoading}
                disableClearable={true}
                InputProps={{ readOnly: true }}
              />
            </RowField>
          )}
          {isAllowToInactiveOrOnHoldPatient && (
            <>
              <Controller
                control={control}
                name={'onHold'}
                render={(renderProps) => {
                  const { label, ...rest } = getFieldProps(renderProps);
                  return (
                    <RowField label={label}>
                      <AppCheckbox
                        {...rest}
                        disabled={isLoading || resultMakeUnHold.isLoading}
                        onChange={composeFunctions(rest.onChange, onHoldChange)}
                      />
                    </RowField>
                  );
                }}
              />
              <Controller
                name={'onHoldEndDate'}
                control={control}
                render={(renderProps) => {
                  const { label, ...rest } = getFieldProps(renderProps);
                  const changeDate = factoryChangeDate(renderProps.name, label);

                  return (
                    <RowField label={label}>
                      <AppDatePicker
                        {...rest}
                        format={APP_FORMAT_DATE}
                        onAccept={changeDate}
                        onBlur={composeFunctions(rest.onBlur, changeDate)}
                      />
                    </RowField>
                  );
                }}
              />
              <Controller
                control={control}
                name={'onHoldReason'}
                render={(renderProps) => {
                  const { label, ...rest } = getFieldProps(renderProps);
                  const maxLength = getMaxLength(renderProps.name as keyof FormModel);
                  return (
                    <RowField label={label}>
                      <AppInput
                        {...rest}
                        inputProps={{
                          maxLength,
                        }}
                        disabled={isLoading}
                        disableClearable={true}
                        onChange={composeFunctions(
                          rest.onChange,
                          factoryChangeInput(rest.name, label),
                        )}
                      />
                    </RowField>
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
      {stateOnHoldDialog.isOpen && userPatientProfileID && (
        <DialogPatientOnHold
          userPatientProfileID={userPatientProfileID}
          onCancel={onHoldCancel}
          onDone={onHoldDone}
        />
      )}
      {stateDeactivateDialog.isOpen && userPatientProfileID && (
        <DialogPatientDeactivateWithConfirm
          userPatientProfileID={userPatientProfileID}
          onClose={stateDeactivateDialog.handleClose}
          onDone={composeFunctions(stateDeactivateDialog.handleClose, onDeactivateSuccess)}
          onConfirm={onDeactivateConfirm}
          onDecline={onDeactivateDecline}
          confirmMessage={tp('are-you-sure-inactive-user')}
        />
      )}
    </>
  );
};
