import React, { useMemo, useState } from 'react';
import style from './index.module.scss';
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Chip,
  Box,
  Collapse,
} from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import SendIcon from '@material-ui/icons/Send';
import { apiMediaPrivate } from 'services/media-private-services';
import { PdfView } from 'components/pdf-view';
import {
  MedicalPrescriptionDialogEmail,
  MedicalPrescriptionDialogEmailToPatient,
} from 'components/medical-prescription';
import { useOpen } from 'AurionCR/components/hooks';
import { useReducerDetails, Detail } from './hooks';
import { ReactComponent as IconPdf } from 'images/icon-pdf.svg';
import { usePermissions } from 'hooks/use-permissions';
import { ButtonPrescriptionForPrint } from './button-prescription-for-print';
import { SendToNeopharm } from './send-to-neopharm';
import { ButtonPrescriptionComplete } from './button-prescription-complete';
import { Alert } from '@material-ui/lab';
import { usePrescriptionCanComplete } from '../../hooks';
import { withRtlRotate } from 'hocs/with-rtl-rotate';
import { CheckPharmacyType } from '../check-pharmacy-type';
import { PREFER_PHARMACY_TYPE } from 'services/user-patient-profile';

const SendIconRotate = withRtlRotate(SendIcon);

interface DocumentPreviewProps {
  isLoading?: boolean;
  url?: string | null;
  title?: string | null | undefined;
}
const DocumentPreview: React.FC<DocumentPreviewProps> = ({ isLoading, title, url }) => {
  const { data: docBase64, isFetching } = apiMediaPrivate.useGetMediaPrivateBase64Query(
    { url: url || '' },
    { skip: !url },
  );

  return <PdfView title={title || 'unknown'} isLoading={isFetching || isLoading} pdf={docBase64} />;
};

interface DocumentItemProps {
  selected: boolean;
  isLoading: boolean;
  title: React.ReactNode;
  onClick: () => void;
}
const DocumentItem: React.FC<DocumentItemProps> = ({ selected, title, isLoading, onClick }) => {
  return (
    <ListItem
      button
      selected={selected}
      disabled={isLoading}
      onClick={onClick}
      className={style.item}
    >
      <ListItemIcon style={{ minWidth: '3.4rem' }}>
        <IconPdf style={{ width: 24, height: 24 }} />
      </ListItemIcon>
      <ListItemText primary={title} />
      <ListItemSecondaryAction>
        {isLoading && <CircularProgress size={16} />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

enum STATIC_DETAIL_ID {
  FOR_CLINIC = '1',
  FOR_PHARMACY = '2',
}

interface Props {
  userPatientProfileID: string | null | undefined;
  pharmacyFollowupID: string | null | undefined;
  prescriptionID: string;
  prescriptionStepID: string;
  prescriptionFilePath?: string | null;
  prescriptionPrintFilePath: string | null | undefined;
  prescriptionWatermarkFilePath?: string | null;
  details: Detail[];
  onCompleted: () => void;
  isActive: boolean;
  isCompleted: boolean;
  isPrint: boolean;
  isSent: boolean;
}

export const TabReady: React.FC<Props> = ({
  userPatientProfileID,
  pharmacyFollowupID,
  prescriptionID,
  prescriptionStepID,
  prescriptionFilePath,
  prescriptionWatermarkFilePath,
  prescriptionPrintFilePath,
  details,
  onCompleted,
  isCompleted,
  isPrint,
  isSent,
}) => {
  const isEmpty = !prescriptionFilePath || !prescriptionWatermarkFilePath;

  const { t, tp } = useTranslate();

  const [docID, setDocID] = useState<STATIC_DETAIL_ID | string>(STATIC_DETAIL_ID.FOR_CLINIC);

  const { state, onGetDocumentUrl } = useReducerDetails({
    userPatientProfilePrescriptionID: prescriptionID,
    details,
  });

  const stateToPharmacy = useOpen();
  const prescriptionDocs = useMemo(() => {
    return [
      ...(prescriptionFilePath
        ? [
            {
              id: STATIC_DETAIL_ID.FOR_CLINIC,
              title: t('prescription-for-clinic'),
              pdfTitle: t('prescription-for-clinic'),
              url: prescriptionFilePath,
              isLoading: false,
              onClick: () => setDocID(STATIC_DETAIL_ID.FOR_CLINIC),
            },
          ]
        : []),
      ...(prescriptionWatermarkFilePath
        ? [
            {
              id: STATIC_DETAIL_ID.FOR_PHARMACY,
              title: t('prescription-for-patient'),
              pdfTitle: t('prescription-for-patient'),
              url: prescriptionWatermarkFilePath,
              isLoading: false,
              onClick: () => setDocID(STATIC_DETAIL_ID.FOR_PHARMACY),
            },
          ]
        : []),
      ...state.details
        .filter((doc) => doc.includeForm29)
        .map((detail) => {
          return {
            id: detail.id,
            title: (
              <>
                <Box mr={'0.6rem'} component={'span'}>
                  ${detail.catalogName}
                </Box>
                <Chip
                  size={'small'}
                  color={'secondary'}
                  label={t('29-gimel')}
                  classes={{ root: style.chip, label: style.chipLabel }}
                />
              </>
            ),
            pdfTitle: detail.catalogName,
            url: detail.url,
            isLoading: detail.isLoading,
            onClick: () => {
              setDocID(detail.id);
              onGetDocumentUrl(detail.id);
            },
          };
        }),
    ];
  }, [t, prescriptionFilePath, prescriptionWatermarkFilePath, state, onGetDocumentUrl]);

  const selectedDoc = useMemo(() => {
    return prescriptionDocs.find((doc) => doc.id === docID);
  }, [prescriptionDocs, docID]);

  const stateToPatient = useOpen();

  const shouldAskCompleteReason = useMemo(() => {
    return [!isPrint, !isSent, !pharmacyFollowupID].every(Boolean);
  }, [isPrint, isSent, pharmacyFollowupID]);

  const isAllowToSendPrescriptionToPatient = usePermissions('isAllowToSendPrescriptionToPatient');
  const isAllowToSendPrescriptionToPharmacy = usePermissions('isAllowToSendPrescriptionToPharmacy');

  const { canComplete } = usePrescriptionCanComplete(prescriptionID);

  return (
    <div className={style.root}>
      <div className={style.controls}>
        <div className={style.buttons}>
          <ButtonPrescriptionComplete
            prescriptionID={prescriptionID}
            prescriptionStepID={prescriptionStepID}
            onDone={onCompleted}
            disabled={isCompleted || isEmpty || !canComplete}
            shouldAskReason={shouldAskCompleteReason}
          />
          {isAllowToSendPrescriptionToPharmacy && (
            <CheckPharmacyType
              userPatientProfileID={String(userPatientProfileID)}
              targetType={PREFER_PHARMACY_TYPE.REGULAR}
              onAction={stateToPharmacy.handleOpen}
            >
              {({ isLoading, onActionWrap }) => {
                return (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={onActionWrap}
                    startIcon={<SendIconRotate />}
                    size={'small'}
                    disabled={isEmpty || !canComplete || isLoading}
                  >
                    {t('send-to-pharmacy')}
                  </Button>
                );
              }}
            </CheckPharmacyType>
          )}
          {isAllowToSendPrescriptionToPatient && (
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={stateToPatient.handleOpen}
              startIcon={<SendIconRotate />}
              disabled={!prescriptionFilePath || !canComplete}
              size={'small'}
            >
              {t('sent-to-patient')}
            </Button>
          )}
          <ButtonPrescriptionForPrint
            prescriptionID={prescriptionID}
            prescriptionPrintFilePath={prescriptionPrintFilePath}
            size={'small'}
            disabled={!canComplete}
          />
        </div>
        <SendToNeopharm
          userPatientProfileID={userPatientProfileID}
          prescriptionID={prescriptionID}
          prescriptionPrintFilePath={prescriptionPrintFilePath}
          pharmacyFollowupID={pharmacyFollowupID}
          disabled={!canComplete}
        />
      </div>
      <div className={style.list}>
        <Collapse in={isEmpty}>
          <Alert severity="error">{tp('prescription-error-generate-or-delay')}</Alert>
        </Collapse>
        <List disablePadding>
          {prescriptionDocs.map((doc, index) => {
            return (
              <DocumentItem
                key={index}
                title={doc.title}
                selected={docID === doc.id}
                isLoading={doc.isLoading}
                onClick={doc.onClick}
              />
            );
          })}
        </List>
      </div>
      <Paper component={'section'} variant={'outlined'} square className={style.preview}>
        <DocumentPreview
          title={selectedDoc?.pdfTitle}
          isLoading={selectedDoc?.isLoading}
          url={selectedDoc?.url}
        />
      </Paper>
      {stateToPharmacy.isOpen && (
        <MedicalPrescriptionDialogEmail
          userPatientProfilePrescriptionID={prescriptionID}
          onClose={stateToPharmacy.handleClose}
          onDone={stateToPharmacy.handleClose}
        />
      )}
      {stateToPatient.isOpen && (
        <MedicalPrescriptionDialogEmailToPatient
          userPatientProfilePrescriptionID={prescriptionID}
          onClose={stateToPatient.handleClose}
          onDone={stateToPatient.handleClose}
        />
      )}
    </div>
  );
};
