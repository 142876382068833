import React, { memo, useEffect } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError } from 'hooks';
import { DialogHeading } from 'components/dialog-title';
import { Stack } from 'components/stack';
import { DialogUserPatientDeclarationContent } from '../dialog-user-patient-profile-declaration-content';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useCurrentUser } from '../../../hooks';

const useFetchActivities = apiUserPatientProfileSessions.useGetSessionsPatientSurveyQuery;
const useFetchPatient = apiUserPatientProfile.useGetPatientDetailsForPreviewSurveyQuery;
const useSetViewedByDoctor =
  apiUserPatientProfileSessions.useSetSessionsPatientSurveyAsViewedMutation;

interface Props {
  itemID: string;
  userPatientProfileID: string;
  onClose: () => void;
}

export const DialogUserPatientDeclaration = memo<Props>(
  ({ userPatientProfileID, itemID, onClose }) => {
    const { tp } = useTranslate();

    const { $isDoctor } = useCurrentUser();

    const resultActivities = useFetchActivities(itemID);
    useEffectError(resultActivities.error);
    const activityRows = resultActivities.data?.sessionItems || [];

    const resultPatient = useFetchPatient(userPatientProfileID);
    useEffectError(resultPatient.error);

    const [triggerSetVieWed] = useSetViewedByDoctor();

    const loading = resultActivities.isLoading || resultPatient.isLoading;

    useEffect(() => {
      if (!$isDoctor) return;
      if (!resultActivities.data) return;
      if (resultActivities.data.doctorViewed) return;

      triggerSetVieWed({ userPatientProfileID, userPatientProfileSessionID: itemID });
    }, [$isDoctor, resultActivities.data, userPatientProfileID, itemID, triggerSetVieWed]);

    const entryDate = resultActivities.data?.entryDate;

    return (
      <Dialog open={true} fullWidth maxWidth={'lg'} onClose={onClose}>
        <DialogHeading title={tp('survey-title')} onClose={onClose} />
        <DialogContent>
          {loading && (
            <Stack alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
          {!loading && (
            <DialogUserPatientDeclarationContent
              activityRows={activityRows}
              data={resultPatient.data}
              entryDate={entryDate}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  },
);
