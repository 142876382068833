import { PatchPartial } from 'utils/types';
import { renderColumn, renderHeader } from '../components';
import { DataGridLightColumn } from '../models';

type Column<T, K extends keyof T> = PatchPartial<DataGridLightColumn<T, K>, 'field'>;

const getDefaultColumn = <T, K extends keyof T>(
  initData: Column<T, K>,
): DataGridLightColumn<T, K> => {
  return {
    id: String(initData.field),
    renderColumn,
    renderHeader,
    ...initData,
  };
};

export const createColumn = <T, K extends keyof T>(column: Column<T, K>) => {
  return getDefaultColumn<T, K>(column);
};
