import { iSupportMeeting } from '../edit-meeting';
import { DAY_OF_WEEK } from 'services/user-employee-profile-work-logs';
import { UserPatientProfile } from 'services/user-patient-profile';
import { SUPPORT_MEETING_PATIENT_OPTIONS } from '../../helpers';
import { ClinicalMeeting } from 'services/clinical-meetings';

export interface iTaskManager {
  // flags
  init: boolean;
  loading: boolean;
  view: 'calendar' | 'list';
  // dialogs
  editMeeting: Partial<iSupportMeeting> | null;
  confirmDeleteMeeting: iTMCalendarSupportMeeting | null;
  sendSms: iTMSendSms | null;
  dialogUserPatientProfileSession: Partial<iTMCalendarSupportMeeting> | null;
  dialogNotebookFormGenerator: (Partial<iTMCalendarSupportMeeting> & { notebookID: string }) | null;
  dialogPrescription: Partial<iTMCalendarSupportMeeting> | null;

  // sources
  employees: { list: iTMEmployee[]; map: { [id: string]: iTMEmployee } };
  meetingTypes: { list: iTMMeetingType[]; map: { [id: string]: iTMMeetingType } };
  times: iTMTime[];

  // state data
  calendar: iTaskManagerCalendar;
  list: iTaskManagerList;
  itemData: iTMListSupportMeeting | null;

  clinicalMeetings: Array<Pick<ClinicalMeeting, 'id' | 'userPatientProfileID'>>;
}

export interface iTMEmployee {
  appIdentityUserID: string;
  userPhoto: string;
  fullName: string;
  department: string;
  color: string;
  workLogs: { fromTime: string; toTime: string; dayOfWeek: DAY_OF_WEEK; id: string }[];
}

export interface iTMMeetingType {
  id: string;
  title: string;
  color: string;
  icon: string;
  isPrescriptionRenewal: boolean;
  isCanceledMeeting: boolean;
  isActive: boolean;
}

export interface iTMSupportMeeting {
  id: string;

  supportMeetingTypeID: string;

  userEmployeeProfileID: string;
  userEmployeeProfileFullName: string;

  meetingFromDateTime: string | Date;
  meetingToDateTime: string | Date;

  userPatientProfileID: string;
  userPatientProfile: Pick<
    UserPatientProfile,
    | 'firstName'
    | 'lastName'
    | 'dateOfBirth'
    | 'mobilePhone'
    | 'shortRemark'
    | 'isActive'
    | 'isTLC'
    | 'onHold'
    | 'doNotProlongTreatment'
    | 'slowIncreaseWeight'
    | 'changeDosageByDoctorApproval'
  >;
  shortRemark: string;

  isPrescriptionRenewal: boolean;

  userPatientProfileSessionID: string | null;
  userPatientProfileSessionNotebookLabelKey: string | null;
  isActive: boolean;
}

export interface iTMSupportMeetingCanToMove {
  id: iTMSupportMeeting['id'];
  meetingFromDateTime: iTMSupportMeeting['meetingFromDateTime'];
  meetingToDateTime: iTMSupportMeeting['meetingToDateTime'];
  userPatientProfileID: string;
}

export interface iTMCalendarSupportMeeting extends iTMSupportMeeting {}

export interface iTMListSupportMeeting extends iTMSupportMeeting {
  supportMeetingActivitiesCount: number;
  remarks: string | null;
  subscriptions: { endDate: string | Date }[];
  lastActivityType: string | null;
  $isSelected: boolean;
}

export interface iTMFilters {
  supportMeetingTypeIDs: string[];
  search: string;
  taskType: '' | 'userPatientProfileSessionID!=null' | 'userPatientProfileSessionID==null';
  isActivePatient: SUPPORT_MEETING_PATIENT_OPTIONS;
}

export interface iTMDayViewItem {
  id: string;
  rows: number;
  maxRows: number;
  data: iTMCalendarSupportMeeting;
}

export interface iTMDayViewItemResize extends iTMDayViewItem {
  startY: number;
}

export interface iTMTime {
  id: string;
  decimal: number;
}

export interface iTMSendSms {
  supportMeetingID: string;
  userPatientProfileID: string;
  userEmployeeProfileFullName: string;
  toPhoneNumber: string;
}

export interface iTaskManagerList {
  // flags
  loading: boolean;
  isRefreshingActivities: boolean;
  // order
  orderField: string;
  orderDirection: string;
  // pagination
  page: number;
  pageSize: number;
  // filters
  filters: {
    date: Date[];
    userEmployeeProfileID: string;
    remarkForPatientCallStatusID: string | null;
  } & iTMFilters;
  // data
  data: iTMListSupportMeeting[];
  dataCount: number;
  // canToMove
  canToMove: iTMSupportMeetingCanToMove[];
  // trigger update scroll
  triggerScrollUpdate: string;
}

export interface iTaskManagerCalendar {
  loading: boolean;
  // filter
  filters: { date: Date } & iTMFilters;
  // data
  data: iTMCalendarSupportMeeting[];
  dayViewItems: { [id: iTMCalendarSupportMeeting['id']]: iTMDayViewItem[] };
  dayViewItemsPrescription: { [id: iTMCalendarSupportMeeting['id']]: iTMCalendarSupportMeeting[] };
  // dayView
  dialogDayViewItems: iTMDayViewItem[] | null;
  dialogDayViewItemsPrescription: string | null;
  resizeDayViewItem: iTMDayViewItemResize | null;
  startDragDayViewItem: number;
  // canToMove
  canToMove: iTMSupportMeetingCanToMove[];
}

// ACTIONS
export enum iTMActionTypes {
  TM_RESET = 'TM_RESET',
  TM_MERGE = 'TM_MERGE',
  TM_VIEW_MERGE = 'TM_VIEW_MERGE',
  TM_FILTER_MERGE = 'TM_FILTER_MERGE',
  TM_SELECT = 'TM_SELECT',
  SET_CLINICAL_MEETINGS = 'TM_SET_CLINICAL_MEETINGS',
  // saga
  TM_INIT = 'TM_INIT',
  TM_REFRESH = 'TM_REFRESH',
  TM_REFRESH_ACTIVITIES = 'TM_REFRESH_ACTIVITIES',
  TM_DELETE_MEETING = 'TM_DELETE_MEETING',
  TM_NOTEBOOK_PATH = 'TM_NOTEBOOK_PATH',
  TM_CHANGE_VIEW = 'TM_CHANGE_VIEW',
  TM_MOVE_ALL = 'TM_MOVE_ALL',
  TM_MOVE_ITEM = 'TM_MOVE_ITEM',
  TM_LOAD_ITEM_DATA = 'TM_LOAD_ITEM_DATA',
  TM_SEND_MESSAGES_BULK = 'TM_SEND_MESSAGES_BULK',
  EXPORT_TO_EXCEL = 'TM_EXPORT_TO_EXCEL',
}
