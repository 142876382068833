import React, { useCallback } from 'react';
import { ControllerRenderProps, FieldErrors } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';
import { useTranslate } from 'hooks/use-translate';

interface Options {
  errors: FieldErrors;
  emptyHelperText?: React.ReactNode;
}

export const useFieldProps = <T extends Record<string, any>>(options: Options & T) => {
  const { t } = useTranslate();

  return useCallback(
    (renderProps: ControllerRenderProps<any>) => {
      const { errors, emptyHelperText, ...rest } = options;
      const error = errors[renderProps.name];
      return {
        ...rest,
        ...renderProps,
        label: t(fieldToLabelKey(renderProps.name)),
        error,
        helperText: error && error.message ? t(error.message) : emptyHelperText,
      };
    },
    [t, options],
  );
};
