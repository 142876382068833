import React, { memo } from 'react';
import { IGridEditFormProps } from 'components/models';
import { DialogNewPatient } from './dialog-new-patient';
import {
  Editor,
  CreateNotebookForm,
  AllActivitiesGrid,
  ButtonCall,
  ButtonTask,
  ButtonFillHealthSurvey,
  ButtonFieldMember,
  ButtonEndTreatment,
  PatientGridClinicalMeetings,
  PatientGridSupportMeetings,
  ProviderPatientData,
  PATIENT_MEETING_SWITCH,
} from './components';
import { GridActivitiesMedicalInformation } from './grids';
import { usePatientWeights } from './hooks';
import { usePermissions } from 'hooks/use-permissions';
import { UserBoxWeight } from 'components/user-info-boxes';
import {
  EditorPatientSubscriptionEndDate,
  EditorPatientSubscriptionStartDate,
} from './grids/subscriptions-payments/components';

export const DialogPatient = memo<IGridEditFormProps>((props) => {
  if (props.itemID && typeof props.itemID === 'string') {
    const itemID = props.itemID;
    return <Editor {...props} itemID={itemID} />;
  }
  const isAllowToEdit = usePermissions('!isAllowToEditPatientsLimited');
  return isAllowToEdit ? <DialogNewPatient {...props} /> : null;
});

export {
  CreateNotebookForm,
  Editor,
  AllActivitiesGrid,
  ButtonCall,
  ButtonTask,
  ButtonFillHealthSurvey,
  usePatientWeights,
  UserBoxWeight,
  GridActivitiesMedicalInformation,
  ButtonFieldMember,
  ButtonEndTreatment,
  PatientGridClinicalMeetings,
  PatientGridSupportMeetings,
  ProviderPatientData,
  PATIENT_MEETING_SWITCH,
  EditorPatientSubscriptionEndDate,
  EditorPatientSubscriptionStartDate,
};

export type { UpdateSignature, UpdateDescription } from './components';
