import React, { memo, useMemo } from 'react';
import { BoxProps, FormLabel, Box } from '@material-ui/core';
import { usePermissions } from 'hooks/use-permissions';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { TemplatePatient } from 'components/templates/template-patient';
import { TemplateSubscription } from 'components/templates/template-subscription';
import { apiUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';

const useQueryGetPatientInfo = apiUserPatientProfile.useGetPatientDetailsForSupportMeetingQuery;
const useQueryGetSubscriptionInfo =
  apiUserPatientProfileSubscriptions.useGetLatestPatientSubscriptionQuery;

export interface Props extends BoxProps {
  userPatientProfileID: string | null;
  onPreviewPatient?: () => void;
}

export const PatientInfo = memo<Props>(({ userPatientProfileID, onPreviewPatient, ...rest }) => {
  const { t } = useTranslate();

  const resultPatientGet = useQueryGetPatientInfo(userPatientProfileID || '', {
    skip: !userPatientProfileID,
  });
  const patient = resultPatientGet.data;

  const resultSubscriptionGet = useQueryGetSubscriptionInfo(userPatientProfileID || '', {
    skip: !userPatientProfileID,
  });
  const subscription = resultSubscriptionGet.data;

  const isAllowToViewPatients = usePermissions('isAllowToEditPatients');

  const onClickPreviewPatient = useMemo(() => {
    if (!userPatientProfileID) return undefined;
    if (!onPreviewPatient) return undefined;

    return onPreviewPatient;
  }, [userPatientProfileID, onPreviewPatient]);

  return (
    <Box className={style.info} {...rest}>
      <div className={style.infoBox}>
        <FormLabel className={style.infoName}>{t('patient')}</FormLabel>
        <TemplatePatient
          isTLC={!!patient?.isTLC}
          onHold={!!patient?.onHold}
          doNotProlongTreatment={!!patient?.doNotProlongTreatment}
          slowIncreaseWeight={!!patient?.slowIncreaseWeight}
          changeDosageByDoctorApproval={!!patient?.changeDosageByDoctorApproval}
          patient={patient ?? {}}
          style={{ padding: '0.4rem 1.2rem' }}
          disabled={!isAllowToViewPatients || !onClickPreviewPatient}
          onClick={onClickPreviewPatient}
        />
      </div>
      <div className={style.infoBox}>
        <FormLabel className={style.infoName}>{t('program-info-end-date')}</FormLabel>
        <TemplateSubscription
          endDate={subscription?.endDate || ''}
          style={{ padding: '0.4rem 1.2rem' }}
        />
      </div>
    </Box>
  );
});
