import { DataGridLightClasses } from '../../models';
import React, { memo, PropsWithChildren, useMemo } from 'react';

interface Value {
  classes: Partial<DataGridLightClasses>;
}
export const DataGridLightSettingContext = React.createContext<Value | null>(null);

interface Props {
  classes?: Partial<DataGridLightClasses>;
}
export const DataGridLightSettingsProvider = memo<PropsWithChildren<Props>>(
  ({ children, classes }) => {
    const value = useMemo(() => {
      return {
        classes: { ...classes },
      };
    }, [classes]);
    return (
      <DataGridLightSettingContext.Provider value={value}>
        {children}
      </DataGridLightSettingContext.Provider>
    );
  },
);
