import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from 'store/store';
import * as dynamic from 'utils/dynamic-helpers';
import { selectLoosingWeightsFilters } from './selectors';
import {
  createFilterDateLessOrEqualsISO,
  createFilterDateMoreOrEqualsISO,
} from 'utils/dynamic-helpers';
import { ServiceUserPatientProfileSessionItems } from 'services/user-patient-profile-session-item';
import { LoosingWeightItem } from '../models';

export const actionLoosingWeightLoadData = createAsyncThunk<
  LoosingWeightItem[],
  void,
  AppAsyncThunkConfig
>(`LOOSING_WEIGHT/loadData`, async (_, { getState }) => {
  try {
    const { age, serverStartDate, serverEndDate, isActive } = selectLoosingWeightsFilters(
      getState(),
    );

    const {
      data: { value },
    } = await ServiceUserPatientProfileSessionItems.getAllDynamic<LoosingWeightItem>({
      select: dynamic.select(
        'entryValueNumber as weight',
        'fieldInput.inputTypeID as typeID',
        'userPatientProfileSession.userPatientProfileID',
        'userPatientProfileSession.entryDate',
        `new {
            userPatientProfileSession.userPatientProfile.fullName,
            userPatientProfileSession.userPatientProfile.shortRemark,
            userPatientProfileSession.userPatientProfile.idNumber,
            userPatientProfileSession.userPatientProfile.mobilePhone,
            userPatientProfileSession.userPatientProfile.dateOfBirth,
            userPatientProfileSession.userPatientProfile.weight,
            userPatientProfileSession.userPatientProfile.maintenanceWeight,
            userPatientProfileSession.userPatientProfile.isActive,
            userPatientProfileSession.userPatientProfile.isTLC,
            userPatientProfileSession.userPatientProfile.onHold,
            userPatientProfileSession.userPatientProfile.doNotProlongTreatment,
            userPatientProfileSession.userPatientProfile.slowIncreaseWeight,
            userPatientProfileSession.userPatientProfile.changeDosageByDoctorApproval,
            userPatientProfileSession.userPatientProfile.userPatientProfileSubscriptions
                .OrderByDescending(endDate).Where(s => s.isActive)
                .Select(s => new {
                    s.id,
                    s.startDate,
                    s.endDate
            }).FirstOrDefault() as subscription
          } as patient`,
      ),
      filter: dynamic
        .mergeFilters(
          isActive === null
            ? null
            : `userPatientProfileSession.userPatientProfile.isActive==${isActive}`,
          'fieldInput.inputType.isWeight',
          createFilterDateMoreOrEqualsISO('userPatientProfileSession.entryDate', serverStartDate),
          createFilterDateLessOrEqualsISO('userPatientProfileSession.entryDate', serverEndDate),
          `(DateTime.Today.Year - DateTime(userPatientProfileSession.userPatientProfile.dateOfBirth).Year)>=${age[0]}`,
          `(DateTime.Today.Year - DateTime(userPatientProfileSession.userPatientProfile.dateOfBirth).Year)<=${age[1]}`,
        )
        .join('&&'),
      orderBy: dynamic.orderBy('userPatientProfileSession.entryDate desc'),
    });

    return value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
