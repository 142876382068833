import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Tooltip, Typography, TypographyProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { fieldToLabelKey } from 'utils/other';
import { getPatientColor } from 'services/user-patient-profile';
import { DateValue, getAge, isTeenager } from 'utils/dates';

interface LabelProps {
  id: string;
  short?: string;
  color: string | undefined;
}
const Label: React.FC<LabelProps> = ({ id, color, short }) => {
  const { t } = useTranslate();
  const { title, value } = useMemo(() => {
    const labelKey = fieldToLabelKey(id);
    const value = labelKey
      .split('-')
      .map((w) => w[0])
      .filter(Boolean)
      .join('');
    return { title: t(`tooltip-${labelKey}`), value: t(value) };
  }, [id, t]);

  return (
    <Tooltip title={title} arrow>
      <span className={style.label} style={{ backgroundColor: color }}>
        {short || value}
      </span>
    </Tooltip>
  );
};

export interface TemplatePatientProps extends TypographyProps {
  isTLC: boolean | undefined;
  onHold: boolean | undefined;
  isActive: boolean | undefined;
  doNotProlongTreatment: boolean | undefined;
  slowIncreaseWeight: boolean | undefined;
  changeDosageByDoctorApproval: boolean | undefined;
  dateOfBirth: DateValue;
  children: React.ReactNode;
  className?: string;
}

export const TemplatePatientFullName = memo<TemplatePatientProps>(
  ({
    isTLC,
    isActive,
    onHold,
    doNotProlongTreatment,
    slowIncreaseWeight,
    changeDosageByDoctorApproval,
    children,
    className,
    dateOfBirth,
    ...rest
  }) => {
    const { teenager, age } = useMemo(() => {
      return { teenager: dateOfBirth ? isTeenager(dateOfBirth) : null, age: getAge(dateOfBirth) };
    }, [dateOfBirth]);
    const { tp } = useTranslate();
    const labels = useMemo(() => {
      return [
        ...(!isActive ? [{ id: 'InActive', color: getPatientColor({ isActive: false }) }] : []),
        ...(onHold ? [{ id: 'OnHold', color: getPatientColor({ onHold: true }) }] : []),
        ...(isTLC
          ? [{ id: 'tlc', color: getPatientColor({ isTLC: true }), short: tp('tlc') }]
          : []),
        ...(doNotProlongTreatment
          ? [
              {
                id: 'DoNotProlongTreatment',
                color: getPatientColor({ doNotProlongTreatment: true }),
              },
            ]
          : []),
        ...(slowIncreaseWeight
          ? [{ id: 'SlowIncreaseWeight', color: getPatientColor({ slowIncreaseWeight: true }) }]
          : []),
        ...(changeDosageByDoctorApproval
          ? [
              {
                id: 'ChangeDosageByDoctorApproval',
                color: getPatientColor({ changeDosageByDoctorApproval: true }),
              },
            ]
          : []),
        ...(teenager
          ? [{ id: 'age', color: getPatientColor({ isTeenager: true }), short: String(age) }]
          : []),
      ];
    }, [
      tp,
      isTLC,
      isActive,
      onHold,
      doNotProlongTreatment,
      slowIncreaseWeight,
      teenager,
      age,
      changeDosageByDoctorApproval,
    ]);

    const borderColor = getPatientColor({
      isTLC,
      isActive,
      onHold,
      doNotProlongTreatment,
      slowIncreaseWeight,
      isTeenager: teenager,
    });

    const strike = !!onHold || !isActive;

    return (
      <div
        className={clsx(style.root, className)}
        style={{ '--data-color': borderColor } as React.CSSProperties}
      >
        <div className={style.icons}>
          <div className={style.iconsInner}>
            {labels.map(({ id, color, short }) => {
              return <Label id={id} color={color} short={short} />;
            })}
          </div>
        </div>
        <Typography
          color={'inherit'}
          {...rest}
          className={clsx({ [style.border]: labels.length, [style.strike]: strike })}
        >
          {children}
        </Typography>
      </div>
    );
  },
);
