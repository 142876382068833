import clsx from 'clsx';
import { useDataGridLightSettings } from '../../hooks';
import { DataGridLightColumn } from '../../models';
import React, { memo, useMemo } from 'react';
import style from './index.module.scss';

interface Props<T = any> {
  row: T;
  column: DataGridLightColumn<any, any>;
}

export const DataGridLightCell = memo<Props>(({ column, row }) => {
  const { classes } = useDataGridLightSettings();

  const props = useMemo(() => {
    if (typeof column.tdProps === 'function') {
      return column.tdProps(row);
    }
    return column.tdProps;
  }, [column, row]);

  return (
    <td
      {...props}
      className={clsx(
        style.cell,
        classes.cell,
        props?.className,
        {
          [style.clickable]: props?.onClick,
          [style.sticky]: column.stickyLeft || column.stickyRight,
          [style.stickyLeft]: column.stickyLeft,
          [style.stickyRight]: column.stickyRight,
        },
        style.td,
      )}
    >
      {column.renderColumn({ field: column.field, row })}
    </td>
  );
});
