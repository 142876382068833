import { base64ToFileStream, getFileInfo, getFileNameExt, isFileLike } from 'utils/file-uploader';
import { ServiceUserPatientProfileDietDiaryImages } from '../user-patient-profile-diet-diary-images';
import {
  API_USER_PATIENT_PROFILE_DIET_DIARIES,
  DietDiaryFileValue,
  UserPatientProfileDietDiaryDeleteInput,
  UserPatientProfileDietDiaryGetOutput,
  UserPatientProfileDietDiaryPatchInput,
  UserPatientProfileDietDiaryPostInput,
} from './models';
import { apiRtk } from 'utils/rtk-query';
import { apiMediaPrivate, ServiceMediaPrivate } from '../media-private-services';
import { DynamicService } from 'utils/service';
import * as dynamic from 'utils/dynamic-helpers';

class Service extends DynamicService<Components.Schemas.UserPatientProfileDietDiary> {
  private makeFileName = (input: {
    userPatientProfileID: string;
    fileName: string;
    index: number;
  }) => {
    const { userPatientProfileID, index, fileName } = input;
    const ext = getFileNameExt(fileName);
    return `${userPatientProfileID}/files/diet-diary__${index}__${new Date().toISOString()}.${ext}`;
  };
  private saveFileToCloud = async (input: {
    file: DietDiaryFileValue;
    userPatientProfileID: string;
    index: number;
  }): Promise<string> => {
    const { file, userPatientProfileID, index } = input;

    if (file.fileURL) {
      return file.fileURL;
    }
    if (isFileLike(file.file)) {
      const { fileName } = getFileInfo(file.file);
      const __fileName = this.makeFileName({
        fileName,
        userPatientProfileID,
        index,
      });
      const { data } = await ServiceMediaPrivate.uploadFile({
        fileName: __fileName,
        fileStreamString: base64ToFileStream(file.file.value),
      });

      return data.filePath;
    }

    return file.file;
  };
  private saveFilesToCloud = async (input: {
    files: DietDiaryFileValue[];
    userPatientProfileID: string;
  }) => {
    const { files, userPatientProfileID } = input;
    return await Promise.all(
      files.map((file, index) => this.saveFileToCloud({ file, userPatientProfileID, index })),
    );
  };
  private saveImages = async (input: { filePaths: string[]; id: string }) => {
    return ServiceUserPatientProfileDietDiaryImages.createBulk(input);
  };
  postWithFiles = async (input: UserPatientProfileDietDiaryPostInput) => {
    const { files, ...rest } = input;

    const result = await this.post(rest);

    const id = String(result.data.id);

    const filePaths = await this.saveFilesToCloud({
      files,
      userPatientProfileID: String(input.userPatientProfileID),
    });

    await this.saveImages({ id, filePaths });

    return { ...result, data: { ...result.data } };
  };
  patchWithFiles = async (input: UserPatientProfileDietDiaryPatchInput) => {
    const { files, ...rest } = input;

    const result = await this.patch(rest);

    const id = String(rest.id);

    const filePaths = await this.saveFilesToCloud({
      files,
      userPatientProfileID: String(input.userPatientProfileID),
    });

    await this.saveImages({ id, filePaths });

    return { ...result, data: rest };
  };
}

export const ServiceUserPatientProfileDietDiaries = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_DIET_DIARIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_DIET_DIARIES.POST,
  patch: API_USER_PATIENT_PROFILE_DIET_DIARIES.PATCH,
  delete: API_USER_PATIENT_PROFILE_DIET_DIARIES.DELETE,
});

export const apiUserPatientProfileDietDiaries = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getUserPatientProfileDietDiary: builder.query<UserPatientProfileDietDiaryGetOutput, string>({
      queryFn: async (userPatientProfileDietDiaryID, { dispatch }) => {
        try {
          const { data } =
            await ServiceUserPatientProfileDietDiaries.getDynamic<UserPatientProfileDietDiaryGetOutput>(
              userPatientProfileDietDiaryID,
              {
                select: dynamic.select(
                  'id',
                  'userPatientProfileID',
                  'userEmployeeProfileID',
                  'dietDiaryTypeID',
                  'entryDate',
                  'description',
                  'reply',
                  'replyDate',
                  'isViewed',
                  'userPatientProfileDietDiaryImages.Select(i => i.fileURL) as fileURLs',
                ),
              },
            );
          const files = await Promise.all(
            data.fileURLs.map(async (fileURL) => {
              const request = dispatch(
                apiMediaPrivate.endpoints.getMediaPrivateAsBase64.initiate(fileURL),
              );
              request.unsubscribe();

              const base64 = await request.unwrap();
              return { fileURL, file: base64 };
            }),
          );
          return { data: { ...data, files } };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    createUserPatientProfileDietDiary: builder.mutation({
      queryFn: (data: UserPatientProfileDietDiaryPostInput) => {
        return ServiceUserPatientProfileDietDiaries.postWithFiles(data);
      },
    }),
    updateUserPatientProfileDietDiary: builder.mutation({
      queryFn: async (input: UserPatientProfileDietDiaryPatchInput) => {
        try {
          const { data } = await ServiceUserPatientProfileDietDiaries.patchWithFiles(input);
          return { data: data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    deleteUserPatientProfileDietDiary: builder.mutation({
      queryFn: (data: UserPatientProfileDietDiaryDeleteInput) => {
        return ServiceUserPatientProfileDietDiaries.delete(data);
      },
    }),
  }),
});

export * from './models';
