import React, { useMemo } from 'react';
import { Stack } from 'components/stack';
import { MedicalPrescriptionProgramInfoDate } from 'components/medical-prescription/components/program-info-date';
import { ShortRemark } from 'components/short-remark';
import { MedicalPrescriptionLastDate } from 'components/medical-prescription';
import { Box } from '@material-ui/core';
import { TagSurvey } from 'components/dialoog-user-patient-profile-session';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useEffectError } from 'hooks';
import { TemplatePatientFullName } from 'components/templates/template-patient-full-name';
import { makeFullName } from 'utils/app-helpers';

interface Props {
  userPatientProfileID: string;
}
export const NotebookPatientInfo: React.FC<Props> = ({ userPatientProfileID }) => {
  const { tp } = useTranslate();
  const { data: patient, error } =
    apiUserPatientProfile.useGetPatientForGeneralFormQuery(userPatientProfileID);
  useEffectError(error);

  const { maintenanceWeight, shortRemark } = patient || {};

  const title = useMemo(() => {
    const fullName = makeFullName(patient || {});
    return [fullName, patient?.idNumber].filter(Boolean).join(' | ');
  }, [patient]);

  return (
    <Stack spacing={1} alignItems={'center'}>
      <div>
        <TemplatePatientFullName
          variant={'h6'}
          isTLC={patient?.isTLC}
          isActive={patient?.isActive}
          onHold={patient?.onHold}
          doNotProlongTreatment={patient?.doNotProlongTreatment}
          slowIncreaseWeight={patient?.slowIncreaseWeight}
          changeDosageByDoctorApproval={patient?.changeDosageByDoctorApproval}
          dateOfBirth={patient?.dateOfBirth}
        >
          {title}
        </TemplatePatientFullName>
      </div>
      <div>
        <MedicalPrescriptionProgramInfoDate userPatientProfileID={userPatientProfileID} />
      </div>
      {maintenanceWeight && (
        <ShortRemark>
          {tp('maintenance-weight')}: {maintenanceWeight}
        </ShortRemark>
      )}
      {shortRemark && (
        <ShortRemark title={tp('short-remark')} priority={'high'}>
          {shortRemark}
        </ShortRemark>
      )}
      <div>
        <MedicalPrescriptionLastDate
          userPatientProfileID={userPatientProfileID}
          userPatientProfilePrescriptionID={null}
        />
      </div>
      <Box ml={'auto'}>
        <TagSurvey userPatientProfileID={userPatientProfileID} />
      </Box>
    </Stack>
  );
};
