import React, { useState, useContext, createContext, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersContext } from '@material-ui/pickers';
import { fade, withStyles } from '@material-ui/core/styles';
import DateRangePickerTooltip from './date-range-picker-tooltip';
import clsx from 'clsx';
import { APP_FORMAT_DATE } from 'configs/const';
import {useTranslate} from "hooks/use-translate";

export const DateRangePickerContext = createContext<any>({ begin: undefined, end: undefined });

function DateRangePicker({
  classes,
  value,
  onChange,
  labelFunc,
  format = APP_FORMAT_DATE,
  emptyLabel,
  autoOk = true,
  onClose,
  ...props
}: any) {
  const {t} = useTranslate();
  const [begin, setBegin] = useState<any>(value[0]);
  const [end, setEnd] = useState<any>(value[1]);
  const [viewDate, setViewDate] = useState<Date | undefined>(value[0] || new Date());
  const [open, setOpen] = useState(false);
  const utils = useContext<null | any>(MuiPickersContext);
  const formatDate = (date: any) => utils.format(date, format || utils.dateFormat);
  const callOnChange = (dates: any[]) => {
    const dates_ = dates.sort((a, b) => a - b);
    if (dates_[1]) dates_[1].setHours(23, 59, 59, 999);
    onChange(dates_);
  };

  const { min, max } = begin > end ? { min: end, max: begin } : { min: begin, max: end };

  function renderDay(day: any, selectedDate: any, dayInCurrentMonth: any, dayComponent: any) {
    return React.cloneElement(dayComponent, {
      onClick: (e) => {
        e.stopPropagation();
        if (!begin) setBegin(day);
        else if (!end) {
          setEnd(day);
          if (autoOk) {
            callOnChange([begin, day]);
            setOpen(false);
          }
        } else {
          setBegin(day);
          setEnd(undefined);
        }
      },
      className: clsx(classes.day, {
        [classes.hidden]: dayComponent.props.hidden,
        [classes.current]: dayComponent.props.current,
        [classes.isDisabled]: dayComponent.props.disabled,
        [classes.daySelected]: day >= min && day <= max,
        [classes.beginCap]: utils.isSameDay(day, min),
        [classes.endCap]: utils.isSameDay(day, max),
      }),
    });
  }

  useEffect(() => {
    setBegin(value[0]);
    setEnd(value[1]);
    setViewDate(value[0] || new Date());
  }, [value, setBegin, setEnd, setViewDate]);


  return (
    <DateRangePickerContext.Provider value={{ begin: begin, end: end }}>
      <DatePicker
        todayLabel={t('today')}
        okLabel={t('ok')}
        cancelLabel={t('cancel')}
        {...props}
        open={open}
        value={viewDate}
        renderDay={renderDay}
        ToolbarComponent={DateRangePickerTooltip}
        openTo={'date'}
        views={['year', 'month', 'date']}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          callOnChange([begin, end]);
          setOpen(false);
          if (onClose) onClose();
        }}
        onChange={(e: any) => {
          setViewDate(e);
        }}
        onClear={() => {
          setBegin(undefined);
          setEnd(undefined);
          onChange([]);
        }}
        labelFunc={(date, invalid) =>
          labelFunc
            ? labelFunc(
                [begin, end].sort((a, b) => a - b),
                invalid,
              )
            : date && begin && end
            ? `${formatDate(begin)} - ${formatDate(end)}`
            : emptyLabel || ''
        }
      />
    </DateRangePickerContext.Provider>
  );
}

export const styles = (theme: any) => {
  const daySize = 38;
  return {
    day: {
      width: daySize + 4,
      height: daySize + 2,
      borderRadius: 0,
      padding: 0,
      margin: '1px 0',
      color: theme.palette.text.primary,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity),
      },
      '&:focus': {
        backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity),
        '&$daySelected': {
          willChange: 'background-color',
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    dayWithMargin: {
      margin: '1px 2px',
      width: daySize,
      height: daySize,
    },
    dayOutsideMonth: {
      color: theme.palette.text.hint,
    },
    hidden: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    today: {
      '&:not($daySelected)': {
        border: `1px solid ${theme.palette.text.hint}`,
      },
    },
    daySelected: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        willChange: 'background-color',
        backgroundColor: theme.palette.primary.dark,
      },
    },
    dayDisabled: {
      pointerEvents: 'none',
      color: theme.palette.text.hint,
    },
    dayLabel: {
      // need for overrides
    },
    beginCap: {
      borderRadius: '50% 0 0 50%',
    },
    endCap: {
      borderRadius: '0 50% 50% 0',
    },
  };
};

// @ts-ignore
export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker);
