import { DynamicService } from 'utils/service';

import {
  API_TODO_TASKS,
  TodoTask,
  ToDoTaskCreateInput,
  ToDoTaskDetailsForNotification,
  ToDoTaskDetailsForPreview,
  ToDoTaskDoneInput,
  ToDoTaskGetActivitiesInput,
  ToDoTaskGetActivitiesItem,
  TodoTaskPatientLatest,
  ToDoTaskUpdateInput,
} from './models';

import { ServiceUserEmployeeTodoTasks } from 'services/user-employee-profile-todo-tasks';
import { makeFilterDateRange } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import { ActionKey } from '../todo-task-categories';
import { makeChangeLog, schemaChangeLogs } from 'modules/change-log';
import { logConfig, TodoTaskDefinition } from './log';
import { PatchPartial } from 'utils/types';
import { apiTodoTaskActivities } from '../todo-task-activities';

export * from './models';

class Service extends DynamicService<TodoTask> {
  create = async (data: ToDoTaskCreateInput) => {
    const { userEmployeeProfileIDs, ...rest } = data;

    const result = await this.post(rest);

    if (userEmployeeProfileIDs && userEmployeeProfileIDs.length) {
      await ServiceUserEmployeeTodoTasks.createBulk({
        toDoTaskID: result.data.id,
        userEmployeeProfileIDs,
      });
    }

    return result;
  };
  update = async (data: PatchPartial<ToDoTaskUpdateInput, 'id'>) => {
    const { userEmployeeProfileIDs, ...rest } = data;

    const result = await this.patch(rest);

    if ('userEmployeeProfileIDs' in data) {
      await ServiceUserEmployeeTodoTasks.createBulk({
        toDoTaskID: rest.id,
        userEmployeeProfileIDs: userEmployeeProfileIDs || [],
      });
    }

    return result;
  };
  getActivities = async (input: ToDoTaskGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<ToDoTaskGetActivitiesItem>({
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      select: [
        'id',
        'message',
        'details',
        'entryDate',
        'done',
        'sentByUserEmployeeProfile.fullName as employee',
        'toDoTaskActivities.Count() as activities',
      ].join(','),
      orderBy: 'entryDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: [item.message, item.details].filter(Boolean).join(' || '),
      date: String(item.entryDate),
      employee: item.employee,
      download: null,

      done: Boolean(item.done),

      activities: item.activities,
    }));
  };
}

export const ServiceTodoTasks = new Service({
  mainField: 'id',
  getAll: API_TODO_TASKS.GET_ALL_DYNAMIC,
  post: API_TODO_TASKS.POST,
  delete: API_TODO_TASKS.DELETE,
  patch: API_TODO_TASKS.PATCH,
});

export const apiTodoTasks = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getTodoTaskPatientLatest: builder.query<
      TodoTaskPatientLatest | null,
      { userPatientProfileID: string; actionKey: ActionKey }
    >({
      queryFn: async ({ userPatientProfileID, actionKey }) => {
        try {
          const { data } = await ServiceTodoTasks.getAllDynamic({
            select: ['id', 'createdDate'].join(','),
            filter: mergeFilters(
              createFilterEquals('userPatientProfileID', userPatientProfileID),
              createFilterEquals('todoTaskCategory.actionKey', actionKey),
            ).join('&&'),
            take: 1,
            orderBy: 'createdDate desc',
          });

          const item = data.value[0];
          if (!item) {
            return { data: null };
          }

          return { data: { ...item, actionKey } as TodoTaskPatientLatest };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getTodoTaskDetailsForPreview: builder.query<ToDoTaskDetailsForPreview, string>({
      queryFn: async (taskID) => {
        try {
          const { data } = await ServiceTodoTasks.getDynamic<ToDoTaskDetailsForPreview>(taskID, {
            select: [
              'id',
              'createdDate',
              'entryDate',
              'closeDate',
              'message',
              'done',
              'toDoTaskCategoryID',
              'new { sentByUserEmployeeProfile.appIdentityUserID, sentByUserEmployeeProfile.fullName, sentByUserEmployeeProfile.userPhoto } as sendBy',
              'closedByUserEmployeeProfileID != null ? new { closedByUserEmployeeProfile.appIdentityUserID, closedByUserEmployeeProfile.fullName, closedByUserEmployeeProfile.userPhoto } : null as closeEmployee',
              'userEmployeeProfileToDoTasks.Select(e=> new { e.userEmployeeProfile.appIdentityUserID, e.userEmployeeProfile.fullName, e.userEmployeeProfile.userPhoto }) as assignedTo',
            ].join(','),
          });

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getTodoTaskDetailsForNotification: builder.query<ToDoTaskDetailsForNotification, string>({
      queryFn: async (taskID) => {
        try {
          const { data } = await ServiceTodoTasks.getDynamic<ToDoTaskDetailsForNotification>(
            taskID,
            {
              select: [
                'id',
                'rowIndex',
                'userPatientProfileID',
                'sentByUserEmployeeProfileID',
                'message',
                'entryDate',
                'userEmployeeProfileToDoTasks.Select(k => k.userEmployeeProfileID) as userEmployeeProfileIDs',
                'new { userPatientProfile.fullName } as patient',
                'new { toDoTaskCategory.title, toDoTaskCategory.actionKey } as category',
                'new { sentByUserEmployeeProfile.fullName } as sendBy',
              ].join(','),
            },
          );

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, arg, meta) => [{ type: RTK_TAGS.TODO_TASKS, id: arg }],
    }),
    updateTodoTaskDone: builder.mutation<void, ToDoTaskDoneInput>({
      queryFn: async (arg) => {
        const { id, done, employeeProfileID } = arg;
        try {
          await ServiceTodoTasks.patch({
            id,
            done,
            closeDate: done ? new Date().toISOString() : null,
            closedByUserEmployeeProfileID: done ? employeeProfileID : null,
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg, meta) => [{ type: RTK_TAGS.TODO_TASKS, id: arg.id }],
    }),
    updateTodoTask: builder.mutation<void, PatchPartial<ToDoTaskUpdateInput, 'id'>>({
      queryFn: async (arg) => {
        try {
          await ServiceTodoTasks.update(arg);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg, meta) => [{ type: RTK_TAGS.TODO_TASKS, id: arg.id }],
    }),
    updateTodoTaskWithLog: builder.mutation<
      void,
      {
        initData: PatchPartial<ToDoTaskUpdateInput, 'id'>;
        formData: PatchPartial<ToDoTaskUpdateInput, 'id'>;
        remark: string;
      }
    >({
      queryFn: async ({ initData, formData, remark }, { dispatch }) => {
        try {
          const fields = await makeChangeLog(logConfig, {
            getDefinition: (_, params) =>
              ServiceTodoTasks.getDynamic<TodoTaskDefinition>(formData.id, params).then(
                (r) => r.data,
              ),
            update: () => ServiceTodoTasks.update(formData),
            initData,
            formData,
          });

          dispatch(
            apiTodoTaskActivities.endpoints.postTodoTaskMeetingActivity.initiate({
              toDoTaskID: formData.id,
              remarks: remark,
              changes: JSON.stringify(
                schemaChangeLogs.cast({ fields }, { stripUnknown: true, assert: false }),
              ),
            }),
          );

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg, meta) => [
        { type: RTK_TAGS.TODO_TASKS, id: arg.formData.id },
      ],
    }),
    createTodoTask: builder.mutation<TodoTask, ToDoTaskCreateInput>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceTodoTasks.create(input);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg, meta) => [
        ...arg.userEmployeeProfileIDs.map((userEmployeeProfileID) => {
          return {
            type: RTK_TAGS.TODO_TASKS,
            id: `userEmployeeProfileID__${userEmployeeProfileID}`,
          };
        }),
      ],
    }),
  }),
});
