import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {
  getQuarter,
  format,
  addYears,
  subMilliseconds,
  addMonths,
  startOfToday,
  endOfToday
} from "date-fns";
import {Button, Tab, Tabs,InputAdornment, Box, Popover} from "@material-ui/core";
import Select from "../select";
import DateRangePicker from "./date-range-picker";
import DateRangeIcon from '@material-ui/icons/DateRange';
import {APP_FORMAT_DATE} from "configs/const";
import { useTranslate } from "hooks/use-translate";
import { useDateLocale } from "contexts/picker-provider";
import { AppInput, AppInputProps } from "components/app-input";
import clsx from "clsx";
import { convertToDate } from "utils/dates";
import { composeFunctions } from "utils";

const getDatesByYear = (year: number) => {
  const from_ = new Date(year, 0, 1);
  return [from_, subMilliseconds(addYears(from_, 1), 1)];
};
const getDatesByQuarter = (year: number, quarter: number) => {
  const from_ = new Date(year, quarter * 3 - 3, 1);
  return [from_, subMilliseconds(addMonths(from_, 3), 1)];
};
const getDatesByMonth = (year: number, month: number) => {
  const from_ = new Date(year, month - 1, 1);
  return [from_, subMilliseconds(addMonths(from_, 1), 1)];
};

interface DataMultipleContentProps {
  value: any[],
  onChange: any
  showToday?: boolean
}
export const DataMultipleContent = memo<DataMultipleContentProps>((
  {
    value,
    onChange,
    showToday
  }
) => {
  const { locale } = useDateLocale()
  const {t} = useTranslate();

  const [tab, setTab] = React.useState(0);
  // sources
  const [year, setYear] = useState((new Date()).getFullYear());
  const [quarter, setQuarter] = useState(1);
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [dates, setDates] = useState<any[]>([]);
  const DSYear = useMemo(() => [...Array(10)]
      .map((val, i) => ({id: year - i}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
  const DSQuarter = useMemo(() => [...Array(4)]
      .map((val, i) => ({title: locale.localize?.quarter(i + 1, {width: 'abbreviated'}), id: i + 1})),
    [locale]);
  const DSMonth = useMemo(() => [...Array(12)]
      .map((val, i) => ({title: locale.localize?.month(i, {width: 'abbreviated'}), id: i + 1})),
    [locale]);

  const onChangeTab = useCallback((e, value) => {
    setTab(value);
    window.dispatchEvent(new Event('resize'));
  }, [setTab]);

  const onChangeProvider = useCallback((dates: Date[]) => {
    onChange(dates)
  }, [onChange]);
  const onApply = useCallback(() => {
    switch (tab) {
      case 0:
        onChangeProvider([...dates]);
        break;
      case 1:
        onChangeProvider(getDatesByYear(year));
        break;
      case 2:
        onChangeProvider(getDatesByQuarter(year, quarter));
        break;
      case 3:
        onChangeProvider(getDatesByMonth(year, month));
        break;
    }
  }, [tab, year, quarter, month, dates, onChangeProvider]);

  const onChangeYear = useCallback(e => {
    setYear(e.target.value);
    setDates(getDatesByYear(e.target.value));
  }, [setYear, setDates]);
  const onChangeQuarter = useCallback(e => {
    const value_ = e.target.value;
    setQuarter(value_);
    setMonth(value_ * 3 - 2);
    setDates(getDatesByQuarter(year, value_));
  }, [year, setQuarter, setMonth, setDates]);
  const onChangeMonth = useCallback(e => {
    const value_ = e.target.value;
    setMonth(value_);
    setQuarter(getQuarter(new Date(year, value_ - 1, 1)));
    setDates(getDatesByMonth(year, value_));
  }, [year, setMonth, setQuarter, setDates]);
  const onChangeDates = useCallback((value) => {
    setDates([...value]);
    setYear(value[0].getFullYear());
    setQuarter(getQuarter(value[0]));
    setMonth(value[0].getMonth() + 1);
  }, [setYear, setQuarter, setMonth, setDates]);

  const onToday = useCallback(() => {
    onChange([startOfToday(), endOfToday()])
  }, [onChange])
  const onClear = useCallback(() => {
    onChange([])
  }, [onChange])

  const parseValue = useCallback((value: Date[] | undefined[]) => {
    const from = value[0] || new Date();
    const to = value[1];

    setYear(from.getFullYear());
    setQuarter(getQuarter(from));
    setMonth(from.getMonth() + 1);
    setDates((from && to) ? [from, to] : []);
  }, [setYear, setQuarter, setMonth, setDates]);

  // init
  useEffect(() => {
    parseValue(value);
  }, [value, parseValue]);

  return <div className="date-multiple_wrapper">
    <Tabs
      value={tab}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChangeTab}
    >
      <Tab label={t('from-to')}/>
      <Tab label={t('yearly')}/>
      <Tab label={t('quarterly')}/>
      <Tab label={t('monthly')}/>
    </Tabs>
    <div className="tabs-content">
      {tab === 0 &&
      <div className="tab-content">
        <DateRangePicker
          value={dates}
          onChange={onChangeDates}
          variant="static"
        />
      </div>
      }
      {tab !== 0 &&
      <div className="tab-content selects">
        <Select
          settings={{label: 'year', source: DSYear, option: {label: 'id', value: 'id'}}}
          onChange={onChangeYear}
          value={year}
          disableClearable
          autoFocus
        />
        {tab === 2 &&
        <Select
          settings={{label: 'quarter', source: DSQuarter}}
          onChange={onChangeQuarter}
          value={quarter}
          disableClearable
        />
        }
        {tab === 3 &&
        <Select
          settings={{label: 'month', source: DSMonth}}
          onChange={onChangeMonth}
          value={month}
          disableClearable
        />
        }
      </div>
      }
    </div>
    <div className="controls">
      {
        showToday &&
        <Box style={{ flexGrow: 1}}>
          <Button
            onClick={onToday}
            color="primary"
            size="small"
            variant={"outlined"}
          >
            {t('today')}
          </Button>
        </Box>
      }
      <Button
        onClick={onClear}
        color="primary"
        size="small"
      >
        {t('clear')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={onApply}
      >
        {t('apply')}
      </Button>
    </div>
  </div>;
});

interface DateMultipleProp {
  showToday?: boolean
  value: any[]
  onChange: (value: this['value']) => void
}
export const DateMultiple = memo<DateMultipleProp & Omit<AppInputProps, 'value' | 'onChange'>>(({value: _value, onChange, showToday, ...rest}) => {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [open, setOpen] = useState(false);

  const value = useMemo(() => {
    return (_value || []).filter(Boolean).map(convertToDate)
  }, [_value])

  const onOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, [setAnchorEl, setOpen]);
  const onClear = useCallback(() => {
    onChange([]);
  }, [onChange]);
  const onClose = useCallback(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAnchorEl, setOpen]);

  const title = useMemo(() => {
    if (value[0] && value[1]) {
      return `${format(convertToDate(value[0]), APP_FORMAT_DATE)} - ${format(convertToDate(value[1]), APP_FORMAT_DATE)}`;
    } else {
      return '';
    }
  }, [value]);

  return <>
    <AppInput
      {...rest}
      className={clsx('data-range_input', rest?.className)}
      value={title}
      onClick={onOpen}
      onClear={(e) => {
        e?.stopPropagation()
        e?.preventDefault()
        onClear()
      }}
      InputProps={{
        ...rest.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <DateRangeIcon/>
          </InputAdornment>
        ),
      }}
    />
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      className="date-multiple_tooltip"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <DataMultipleContent value={value} onChange={composeFunctions(onChange, onClose)} showToday={showToday}/>
    </Popover>
  </>
});
