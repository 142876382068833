import { pick } from 'lodash-es';
import { getDiff } from 'utils/other';
import * as yup from 'yup';

const defaultShouldFetch = (config: ChangeLogConfig<any, any, any>) => {
  return config.select.includes(' as ');
};
export type ChangeLogConfig<
  InitData extends Record<string, any>,
  FormData extends Record<string, any>,
  Def,
> = {
  select: string;
  getValue: (def: Def, options: { data: InitData | FormData }) => string;
  shouldFetch?: (config: ChangeLogConfig<InitData, FormData, Def>) => boolean;
};

type Options<InitData, FormData, Def> = {
  formData: FormData;
  initData: InitData;

  getDefinition: (initData: InitData, params: { select: string }) => Promise<Def>;
  update: (formData: FormData) => Promise<any>;

  debug?: boolean;
};

export const makeChangeLog = async <
  InitData extends Record<string, any>,
  FormData extends Record<string, any>,
  Def,
>(
  schema: Record<string, ChangeLogConfig<InitData, FormData, Def>>,
  options: Options<InitData, FormData, Def>,
) => {
  const { formData, initData, getDefinition, update, debug = false } = options;
  const formKeys = Object.keys(formData);

  const pickInitData = pick(initData, ...formKeys);
  const diffUpdatedData = getDiff(formData, pickInitData as typeof formData);

  const updatedKeys = Object.keys(diffUpdatedData);

  const configs = Object.values(debug ? schema : pick(schema, ...updatedKeys));

  const shouldFetch = debug
    ? true
    : configs.some((config) => {
        let check = config?.shouldFetch || defaultShouldFetch;
        return check(config);
      });

  const keys = configs.map((conf) => {
    return conf.select;
  });

  const select = Array.from(new Set(keys).keys()).join(',');

  // get for log before patch
  const definitionBefore = shouldFetch
    ? await getDefinition(initData, { select })
    : (initData as Def);

  // patch model
  await update(formData);

  // get for log after patch
  const definitionAfter = shouldFetch
    ? await getDefinition(initData, { select })
    : (formData as Def);

  const changes = updatedKeys
    .filter((key) => key in schema)
    .map((key) => {
      const conf = schema[key];
      const from = conf.getValue(definitionBefore, { data: initData });
      const fromValue = initData[key];
      const to = conf.getValue(definitionAfter, { data: formData });
      const toValue = formData[key];

      return { field: key as keyof FormData, from, fromValue, to, toValue };
    });

  return changes;
};

export const schemaChangeLog = yup.object({
  field: yup.string().required(),
  from: yup.string().required(),
  fromValue: yup.string().required(),
  to: yup.string().required(),
  toValue: yup.string().required(),
});

export const schemaChangeLogs = yup.object({
  fields: yup.array().required('rule-required').of(schemaChangeLog),
});
