import React from 'react';
import { AppInputMask, AppInputMaskedProps } from 'components/app-input-masked';

export const AppPassportNumberInput: React.FC<Omit<AppInputMaskedProps, 'MaskOptions'>> = (
  props,
) => {
  return (
    <AppInputMask
      MaskOptions={{
        mask: /^\d*$/,
      }}
      placeholder={'00000000'}
      {...props}
    />
  );
};
