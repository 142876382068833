import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  iTMCalendarSupportMeeting,
  TMMerge,
  TMDeleteMeeting,
} from 'pages/TaskManager/components/store';
import { DialogConfirm } from 'components/dialog-confirm';

import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';

export const DeleteMeetingItem = memo(() => {
  const { confirmDeleteMeeting } = useAppSelector((state) => state.TaskManager);
  return confirmDeleteMeeting ? <DeleteMeetingItemDialog data={confirmDeleteMeeting} /> : null;
});

interface Props {
  data: iTMCalendarSupportMeeting;
}
export const DeleteMeetingItemDialog = memo<Props>(
  ({ data: { id, userPatientProfile, meetingFromDateTime, meetingToDateTime } }) => {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();
    // state
    const handleCancel = useCallback(() => {
      dispatch(TMMerge({ confirmDeleteMeeting: null }));
    }, [dispatch]);
    const handleDone = useCallback(() => {
      dispatch(TMDeleteMeeting({ id }));
    }, [dispatch, id]);

    // render
    return (
      <DialogConfirm
        title={
          [
            t('delete-item'),
            `${userPatientProfile.firstName} ${userPatientProfile.lastName}`,
            `${format(new Date(meetingFromDateTime), APP_FORMAT_DATE)} ${format(
              new Date(meetingFromDateTime),
              'HH:mm',
            )} - ${format(new Date(meetingToDateTime), 'HH:mm')}`,
          ].join(' ,') + '?'
        }
        onCancel={handleCancel}
        onConfirm={handleDone}
      />
    );
  },
);

export default DeleteMeetingItem;
