import React, { useCallback, useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { apiTodoTaskCategories, TaskTag } from 'services/todo-task-categories';
import { useEffectError, useFieldProps } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { apiTodoTasks, schemaTodoTaskCreate } from 'services/todo-tasks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType } from 'yup';
import { useCurrentUser } from 'components/hooks';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useAppDispatch } from 'store';
import { useTodoTaskNotificationCreated } from 'components/dialog-task';
import { isMutationFulfilled } from 'utils/rtk-query';
import { AppInput } from 'components/app-input';
import { Alert } from '@material-ui/lab';

const useFetchData = apiTodoTaskCategories.useGetTodoTaskCategoryInfoByTagQuery;

const useCreateMutation = apiTodoTasks.useCreateTodoTaskMutation;

const schema = schemaTodoTaskCreate.pick(['message']);
type FormModel = InferType<typeof schema>;

interface Props {
  userPatientProfileSessionID: string;
  userPatientProfileID: string;
  onCancel(): void;
  onDone(): void;
}
export const DialogPrescriptionTask: React.FC<Props> = ({
  userPatientProfileID,
  userPatientProfileSessionID,
  onCancel,
  onDone,
}) => {
  const dispatch = useAppDispatch();
  const { appUserID } = useCurrentUser();
  const { tp } = useTranslate();

  const resultGet = useFetchData(TaskTag.PrescriptionRequired);
  useEffectError(resultGet.error);
  const categoryData = resultGet.data;

  const [createItem, resultCreateItem] = useCreateMutation();
  useEffectError(resultCreateItem.error);

  const { onSend } = useTodoTaskNotificationCreated();

  const { reset, control, handleSubmit, errors } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: ' ' });

  useEffect(() => {
    if (!categoryData) return;
    reset(
      schema.cast({
        message: categoryData.defaultMessage,
      }),
    );
  }, [categoryData, reset]);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      if (!categoryData) return;

      try {
        const payload = await schemaTodoTaskCreate.validate({
          message: formData.message,
          toDoTaskCategoryID: categoryData.id,
          userPatientProfileID,
          userEmployeeProfileIDs: categoryData.defaultEmployeeProfileIDs,
          entryDate: new Date().toISOString(),
          sentByUserEmployeeProfileID: appUserID,
          userPatientProfileSessionID: userPatientProfileSessionID,
        });
        const res = await createItem(payload);

        if (isMutationFulfilled(res)) {
          onSend(res.data);
          onDone();
        }
      } catch (e: any) {
        dispatch(notifyRequestResult('Schema error', 'error'));
      }
    },
    [
      categoryData,
      createItem,
      userPatientProfileID,
      appUserID,
      dispatch,
      onSend,
      onDone,
      userPatientProfileSessionID,
    ],
  );

  const isLoading = resultGet.isLoading || resultCreateItem.isLoading;

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      <DialogHeading isLoading={isLoading} title={tp('create-task')} />
      <DialogContent>
        <Box mb={1}>
          <Alert severity={'info'}>{tp('does-patient-need-prescription')}</Alert>
        </Box>

        <Controller
          control={control}
          name={'message'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                multiline
                minRows={2}
                disabled={isLoading}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} disabled={isLoading} onClick={onCancel}>
          {tp('no')}
        </ButtonCancel>
        <ButtonSave isCreate={false} disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {tp('yes')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
};
