import React, { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import Footer from './footer';
import { useForm } from 'react-hook-form';
import { FormController, Select } from 'AurionCR/components/formV2';
import { API_SUBSCRIPTIONS } from 'services/subscription';
import { apiStatic, Loading, requestError } from 'AurionCR/components';
import style from './index.module.scss';
import { API_USER_PATIENT_PROFILE } from 'services/user-patient-profile';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useDispatch } from 'react-redux';
import { add } from 'date-fns';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  onCancel: (e?: any) => void;
  onNext: (e?: any) => void;
  userPatientProfileID: string;
}

export const State1 = memo<Props>(({ onCancel, onNext, userPatientProfileID }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { errors, control, handleSubmit } = useForm({
    defaultValues: {
      subscriptionID: '',
    },
  });
  // handlers
  const onSubmit = useCallback(
    (formData: any) => {
      setIsLoading(true);
      Promise.all([
        apiStatic.get(API_SUBSCRIPTIONS.GET_ALL_DYNAMIC, {
          params: {
            select: 'labelKey,durationMonths,listPrice',
            filter: `id=="${formData.subscriptionID}"`,
          },
        }),
        apiStatic.get(API_USER_PATIENT_PROFILE.GET_ALL_DYNAMIC, {
          params: {
            select: 'email,fullName,idNumber',
            filter: `appIdentityUserID=="${userPatientProfileID}"`,
          },
        }),
      ])
        .then((response) => {
          const [
            {
              data: {
                // eslint-disable-next-line no-useless-computed-key
                value: { [0]: subscription },
              },
            },
            {
              data: {
                // eslint-disable-next-line no-useless-computed-key
                value: { [0]: patient },
              },
            },
          ] = response;
          const durationMonths = subscription?.durationMonths
            ? Number(subscription.durationMonths) || 0
            : 0;
          onNext({
            subscriptionID: formData.subscriptionID,
            description: subscription?.labelKey || '',
            startDate: new Date(),
            endDate: add(new Date(), { months: durationMonths }),
            email: patient?.email || '',
            clientName: patient?.fullName || '',
            clientVatID: patient?.idNumber || '',
            sum: subscription?.listPrice || 0,
            durationMonths,
          });
        })
        .catch((e) => {
          dispatch(notifyRequestResult(requestError(e), 'error'));
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onNext, userPatientProfileID],
  );
  // render
  return (
    <div className={clsx(style.state, style.state1)}>
      <form className={style.contentForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.contentCenter}>
          <div className={style.wrapper}>
            <div className={clsx(style.title)}>{t('choose-subscription-title')}</div>
            <div className={clsx(style.description)}>{t('choose-subscription-description')}</div>
            <FormController
              name={'subscriptionID'}
              label={t('choose')}
              placeholder={''}
              as={Select}
              rules="required"
              errors={errors}
              control={control}
              source={`${API_SUBSCRIPTIONS.GET_ALL_DYNAMIC}?Select=id,labelKey as title&filter=isActive == true`}
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
      <Footer onCancel={onCancel} onNext={handleSubmit(onSubmit)} disabled={isLoading} />
      <Loading active={isLoading} />
    </div>
  );
});

export default State1;
