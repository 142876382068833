import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { TemplateEmployee } from 'components/templates/template-employee';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import React from 'react';
import { DietDiaryType } from 'services/diet-diary-types';
import { permissionKey } from 'services/user-employee-profile-permissions';
import {
  API_USER_PATIENT_PROFILE_DIET_DIARIES,
  UserPatientProfileDietDiary,
} from 'services/user-patient-profile-diet-diaries';
import { excelTemplateDate } from 'utils/dates';
import {
  createFilterContains,
  createFilterEquals,
  createFilterSmartSearch,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { ColumnReply } from './components';
import { TemplateDate } from 'components/templates/template-date';
import { TemplateIconWithText } from 'components/templates/template-icon-with-text';
import { ColumnImages } from './components/column-images';

export interface GridModel extends UserPatientProfileDietDiary {
  icon: DietDiaryType['icon'];
  color: DietDiaryType['color'];
  iconTitle: DietDiaryType['title'];
  fileURLs: string[];
}
interface Options {
  userPatientProfileID: string;
}
export default (options: Options): any => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_DIET_DIARIES.GET_ALL_DYNAMIC,
      filters: createFilterEquals('userPatientProfileID', options.userPatientProfileID),
      selected: [
        'isActive',
        'userPatientProfileDietDiaryImages.Select(i => i.fileURL) as fileURLs',
      ].join(','),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_DIET_DIARIES.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_DIET_DIARIES.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_DIET_DIARIES.DELETE,
    },
    sortable: {
      field: 'createdDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    'edit',
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  toolbar: {
    title: '',
    excelTitle: 'diet-diaries',
    filters: [
      {
        field: 'text',
        options: {
          type: 'string',
          label: 'text',
          valueTemplate: ({ value }: any) =>
            mergeFilters(
              createFilterContains<GridModel>(['description', 'reply'], value),
              createFilterSmartSearch<GridModel>(
                ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
                value,
              ),
            ).join('||'),
        },
      },
    ],
    controls: null,
    controlsDefault: {
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'diet-diary-type',
      field: 'dietDiaryType.color,dietDiaryType.icon,dietDiaryType.title as iconTitle',
      template: (data: GridModel) => (
        <TemplateIconWithText color={data.color} iconSrc={data.icon} title={data.iconTitle} />
      ),
    },
    {
      title: 'files',
      field: 'id',
      template: (data: GridModel) => <ColumnImages row={data} />,
      sortable: 'userPatientProfileDietDiaryImages.Count()',
    },
    {
      title: 'entry-date',
      field: 'entryDate',
      template: (data: GridModel) => <TemplateDate date={data.entryDate} />,
      tdClasses: 'min-width-date-time',
      valueTemplate: (data: GridModel) => excelTemplateDate(data.entryDate, APP_FORMAT_DATE_TIME),
    },
    {
      title: 'description',
      field: 'description',
      tdClasses: 'min-width-200',
    },
    {
      title: 'reply',
      field: 'reply',
      editor: {
        type: 'custom',
        required: true,
        component: ColumnReply,
      },
    },
    {
      title: 'replied-by-employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileID',
      sortable: 'userEmployeeProfile.firstName',
      template: (data: GridModel) => (
        <TemplateEmployee employee={data.userEmployeeProfileID || ''} />
      ),
    },
    {
      title: 'replied-by-employee-date',
      field: 'replyDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
      tdClasses: 'min-width-date-time',
      valueTemplate: (data: GridModel) => excelTemplateDate(data.replyDate, APP_FORMAT_DATE_TIME),
    },
    {
      title: 'viewed',
      field: 'isViewed',
      template: 'boolean',
      editor: {
        type: 'boolean',
      },
    },
  ],
});
