import { Button } from '@material-ui/core';
import { useI18n } from 'AurionCR/components';
import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import React from 'react';
import { NOTEBOOK_INDEXES } from 'services/notebook';
import {
  API_USER_PATIENT_PROFILE_SESSIONS,
  UserPatientProfileSession,
} from 'services/user-patient-profile-session';
import { excelTemplateDate } from 'utils/dates';
import {
  createFilterEquals,
  createFilterValueArrayEquals,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { TemplateDate } from 'components/templates/template-date';

const ViewMore: React.FC = ({ id, data, control, onTasks }: any) => {
  const { t } = useI18n();
  return data?.totalItems > 0 ? (
    <Button
      onClick={() => onTasks([['onHandle', { type: 'edit', id, data }]])}
      disabled={control.disabled}
      color={'primary'}
      size={'small'}
    >
      {t('view-more')}
    </Button>
  ) : (
    <div />
  );
};

export default (userPatientProfileID: string): any => ({
  id: 'grid_user-patient-profile-sessions',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSIONS.GET_ALL_DYNAMIC,
      filters: mergeFilters(
        createFilterValueArrayEquals('notebook.rowIndex', [
          NOTEBOOK_INDEXES.QUESTIONNAIRE_EN,
          NOTEBOOK_INDEXES.QUESTIONNAIRE_HE,
        ]),
        createFilterEquals('userPatientProfileID', userPatientProfileID),
      ).join('&&'),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SESSIONS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SESSIONS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SESSIONS.DELETE,
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    excelTitle: 'questionnaire',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  controls: [
    {
      type: 'custom',
      template: ViewMore,
    },
  ],
  columns: [
    {
      title: 'date',
      field: 'entryDate',
      template: (data: UserPatientProfileSession) => <TemplateDate date={data.entryDate} />,
      tdClasses: 'mobile-no-wrap',
      valueTemplate: (data: UserPatientProfileSession) => excelTemplateDate(data.entryDate),
    },
    {
      title: 'notebook',
      field: 'notebook.labelKey as notebookLabelKey',
      sortable: 'notebook.labelKey',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'total-answers',
      field: 'userPatientProfileSessionItems.count() as totalItems',
      sortable: 'userPatientProfileSessionItems.count()',
      tdClasses: 'mobile-no-wrap',
    },
  ],
});
