import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { Controls } from 'AurionCR/components/formV2';
import { DialogBase } from 'AurionCR/components';
import { DialogHeading } from 'components/dialog-title';
import { useCurrentUser, useSourceDietDiaryTypes } from 'components/hooks';
import { usePermissions } from 'hooks/use-permissions';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import {
  apiUserPatientProfileDietDiaries,
  schemaDietDiary,
} from 'services/user-patient-profile-diet-diaries';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFieldProps } from 'hooks';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { AppSelect } from 'components/app-select';
import { AppInput } from 'components/app-input';
import { AppDatePicker } from 'components/app-date-picker';
import { AppCheckbox } from 'components/app-checkbox';
import { composeFunctions } from 'utils';
import { SelectEmployee } from 'components/select-employee';
import { InferType } from 'yup';
import { useOpen } from 'AurionCR/components/hooks';
import { isMutationFulfilled } from 'utils/rtk-query';
import { FoodImageUploader } from '../food-image-uploader';
import { dateFormat } from 'utils/dates';
import { yupResolver } from '@hookform/resolvers/yup';
import style from './index.module.scss';

const schema = schemaDietDiary;
type FormModel = InferType<typeof schema>;

interface FormProps {
  isLoading: boolean;
  isNew?: boolean;
}

export const Form: React.FC<FormProps> = ({ isLoading, isNew }) => {
  const { control, errors, getValues, setValue } = useFormContext<FormModel>();
  const { t } = useTranslate();
  const { appUserID } = useCurrentUser();
  const sourceDietDiaryTypes = useSourceDietDiaryTypes();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const onBlurReply = useCallback(() => {
    const v = getValues('reply');

    if (!v) return;

    setValue('userEmployeeProfileID', appUserID);
    setValue('isViewed', true);
    setValue('replyDate', new Date().toISOString());
  }, [setValue, getValues, appUserID]);

  return (
    <>
      <div className={style.root}>
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Controller
                name={'dietDiaryTypeID'}
                control={control}
                render={(renderProps) => (
                  <AppSelect
                    {...getFieldProps(renderProps)}
                    options={sourceDietDiaryTypes.data}
                    loading={sourceDietDiaryTypes.loading}
                    disabled={isLoading}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name={'entryDate'}
                control={control}
                render={(renderProps) => (
                  <AppDatePicker
                    {...getFieldProps(renderProps)}
                    format={APP_FORMAT_DATE_TIME}
                    disabled={isLoading || !isNew}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Controller
            control={control}
            name={'files'}
            render={(renderProps) => (
              <FoodImageUploader disabled={isLoading} {...getFieldProps(renderProps)} />
            )}
          />
          <Controller
            name={'description'}
            control={control}
            render={(renderProps) => (
              <AppInput
                {...getFieldProps(renderProps)}
                multiline
                minRows={4}
                maxRows={8}
                disabled={isLoading}
              />
            )}
          />
        </div>
        <Divider orientation={'vertical'} />
        <div>
          <Controller
            name={'reply'}
            control={control}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <AppInput
                  {...props}
                  onBlur={composeFunctions(props.onBlur, onBlurReply)}
                  multiline
                  minRows={4}
                  maxRows={8}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name={'userEmployeeProfileID'}
            control={control}
            render={(renderProps) => (
              <SelectEmployee
                {...getFieldProps(renderProps)}
                label={t('replied-by-employee')}
                disabled={isLoading}
              />
            )}
          />
          <Controller
            name={'replyDate'}
            control={control}
            render={(renderProps) => (
              <AppDatePicker
                {...getFieldProps(renderProps)}
                format={APP_FORMAT_DATE_TIME}
                label={t('replied-by-employee-date')}
                disabled={isLoading}
              />
            )}
          />
          <Controller
            name={'isViewed'}
            control={control}
            render={(renderProps) => (
              <AppCheckbox
                {...getFieldProps(renderProps)}
                title={t('viewed')}
                disabled={isLoading}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

interface Props {
  userPatientProfileID: string;
  itemID: string | true;
  onClose: () => void;
  onRefresh: () => void;
}

const useFetchQuery = apiUserPatientProfileDietDiaries.useGetUserPatientProfileDietDiaryQuery;
const usePostMutation =
  apiUserPatientProfileDietDiaries.useCreateUserPatientProfileDietDiaryMutation;
const usePatchMutation =
  apiUserPatientProfileDietDiaries.useUpdateUserPatientProfileDietDiaryMutation;
const useDeleteMutation =
  apiUserPatientProfileDietDiaries.useDeleteUserPatientProfileDietDiaryMutation;

export default memo<Props>(({ onClose, itemID, userPatientProfileID, onRefresh }) => {
  const formIsNew = itemID === true;

  const methods = useForm({
    defaultValues: schema.cast({
      userPatientProfileID,
    }),
    resolver: yupResolver(schema),
  });
  const { reset, register } = methods;

  const resultFetch = useFetchQuery(String(itemID), {
    skip: formIsNew,
    refetchOnMountOrArgChange: true,
  });
  useEffectError(resultFetch.error);
  useEffect(() => {
    if (!resultFetch.data) return;
    reset(schema.cast({ ...resultFetch.data }, { assert: false }));
  }, [reset, resultFetch.data]);

  const [postItem, resultPost] = usePostMutation();
  useEffectError(resultPost.error);

  const [patchItem, resultPatch] = usePatchMutation();
  useEffectError(resultPatch.error);

  const [deleteItem, resultDelete] = useDeleteMutation();
  useEffectError(resultDelete.error);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      const result = formIsNew
        ? await postItem({ ...formData } as any)
        : await patchItem({ ...formData, id: String(itemID) } as any);

      if (isMutationFulfilled(result)) {
        onClose();
        onRefresh();
      }
    },
    [formIsNew, postItem, patchItem, itemID, onClose, onRefresh],
  );

  const onDelete = useCallback(async () => {
    const result = await deleteItem({ id: String(itemID) });

    if (isMutationFulfilled(result)) {
      onClose();
      onRefresh();
    }
  }, [deleteItem, itemID, onClose, onRefresh]);

  const deleteOpenState = useOpen();
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');

  const isLoading =
    resultFetch.isFetching ||
    resultPost.isLoading ||
    resultPatch.isLoading ||
    resultDelete.isLoading;

  const sourceDietDiaryTypes = useSourceDietDiaryTypes();

  const title = useMemo(() => {
    if (!resultFetch.data) {
      return '';
    }

    const { entryDate, dietDiaryTypeID } = resultFetch.data;
    return [
      dateFormat(entryDate),
      dietDiaryTypeID ? sourceDietDiaryTypes.map[dietDiaryTypeID]?.title : null,
    ]
      .filter(Boolean)
      .join(' | ');
  }, [resultFetch.data, sourceDietDiaryTypes.map]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogHeading isLoading={isLoading} title={title} onClose={onClose} />
      <DialogContent style={{ position: 'relative' }}>
        <FormProvider {...methods}>
          <input hidden {...register('userPatientProfileID')} />
          <Form isNew={formIsNew} isLoading={isLoading} />
        </FormProvider>
        {deleteOpenState.isOpen && isAllowToDelete && (
          <DialogBase
            title={title}
            onClose={deleteOpenState.handleClose}
            onConfirm={composeFunctions(deleteOpenState.handleClose, onDelete)}
            isLoading={resultDelete.isLoading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Controls
          size="medium"
          state={!formIsNew ? 'save' : 'create'}
          loading={isLoading}
          onSubmit={methods.handleSubmit(onSubmit)}
          onDelete={deleteOpenState.handleOpen}
          onCancel={onClose}
          showDelete={!formIsNew && isAllowToDelete}
        />
      </DialogActions>
    </Dialog>
  );
});
