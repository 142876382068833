import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: RootState) => state.sideEffects;
export const selectSideEffectsStatuses = createSelector(selectState, ({ isLoading }) => {
  return { isLoading };
});
export const selectSideEffectsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
const selectSideEffects = createSelector(selectState, ({ sideEffects }) => {
  return sideEffects;
});

export const selectSideEffectsData = createSelector(selectSideEffects, (data) => {
  let patientsMap = new Map<string, typeof data>();

  data.forEach((item) => {
    const existing = patientsMap.get(item.userPatientProfileID) || [];
    existing.push(item);
    patientsMap.set(item.userPatientProfileID, existing);
  });

  return Array.from(patientsMap.entries()).map((entry) => {
    const list = entry[1];
    const firstRecord = list[0];

    return {
      entryDate: firstRecord.entryDate,
      appIdentityUserID: entry[0],
      fullName: String(firstRecord.patient.fullName),
      shortRemark: String(firstRecord.patient.shortRemark),
      idNumber: String(firstRecord.patient.idNumber),
      mobilePhone: String(firstRecord.patient.mobilePhone),
      dateOfBirth: String(firstRecord.patient.dateOfBirth),
      subscription: firstRecord.patient.subscription,
      sideEffects: list.map((item) => item.sideEffects).flat(),
      isActive: !!firstRecord.patient.isActive,
      isTLC: !!firstRecord.patient.isTLC,
      onHold: !!firstRecord.patient.onHold,
      doNotProlongTreatment: !!firstRecord.patient.doNotProlongTreatment,
      slowIncreaseWeight: !!firstRecord.patient.slowIncreaseWeight,
      changeDosageByDoctorApproval: !!firstRecord.patient.changeDosageByDoctorApproval,
      userEmployeeProfileID: firstRecord.employee.appIdentityUserID,
    };
  });
});
