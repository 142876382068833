import { PickServerObject } from 'utils/types';
import { Notebook } from 'services/notebook';
import {
  NotebookFormGeneratorClinicalMeetingBefore,
  NotebookFormGeneratorSupportMeetingBefore,
  NotebookFormGenerator,
} from './components';

export const getNotebookFormComponent = (
  notebook: PickServerObject<Notebook, 'displayNextMeetingForm' | 'isForClinicalMeeting'>,
) => {
  if (notebook.isForClinicalMeeting) {
    return NotebookFormGeneratorClinicalMeetingBefore;
  }
  if (notebook.displayNextMeetingForm) {
    return NotebookFormGeneratorSupportMeetingBefore;
  }
  return NotebookFormGenerator;
};
