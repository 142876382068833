import { DataGridLightRenderColumnProps } from '../../models';
import { format, isValid } from 'date-fns';
import React, { memo, useMemo } from 'react';
import style from './index.module.scss';
import { APP_FORMAT_DATE } from 'configs/const';

interface Props extends DataGridLightRenderColumnProps<any, any> {
  format?: string;
}

export const ColumnDate = memo<Props>(({ row, field, format: formatPattern = APP_FORMAT_DATE }) => {
  const value = row[field];

  const dateValue = useMemo(() => {
    return isValid(new Date(value)) ? format(new Date(value), formatPattern) : null;
  }, [formatPattern, value]);
  return (
    <div className={style.wrap} title={value}>
      {dateValue}
    </div>
  );
});

export const renderColumnDate = (props: DataGridLightRenderColumnProps<any, any>) => {
  return <ColumnDate {...props} />;
};
