import { DynamicService } from 'utils/service';
import {
  API_CLINICAL_MEETINGS,
  AttacheNotebookToClinicalMeetingInput,
  CancelClinicalMeetingInput,
  CheckClinicMeetingType,
  ClinicalMeeting,
  ClinicalMeetingExcel,
  ClinicalMeetingGetActivitiesInput,
  ClinicalMeetingGetActivitiesItem,
  ClinicalMeetingSendSmsToPatient,
  CreatePatientTodayClinicalMeeting,
  GetMeetingExtendedInformationOutput,
  GetPatientTodayClinicalMeetingsInput,
  MeetingForEndOfTreatmentConsultation,
  MeetingsWithDoctorOrDietitianItem,
  PatientTodayClinicalMeeting,
  TryDeactivatePatientMeetingData,
} from './models';

import {
  addMinutes,
  addMonths,
  differenceInMonths,
  endOfDay,
  format,
  isAfter,
  startOfDay,
} from 'date-fns';
import { convertToDate, DateValue } from 'utils/dates';

import { makeFilterDateRange, setDateToNearestTimeSlotFlor } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS, transformResponseDynamic } from 'utils/rtk-query';
import { FixServerObject, PatchPartial, Unset } from 'utils/types';
import { APP_FORMAT_DATE, APP_FORMAT_TIME, CALENDAR_RANGE_MINUTES } from 'configs/const';
import { apiClinicalMeetingTypes, MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import {
  apiClinicalMeetingSubjects,
  CLINICAL_MEETING_SUBJECT_TYPE,
  CLINICAL_MEETING_SUBJECTS_IDS,
} from '../clinical-meeting-subjects';
import { apiUserPatientProfile, ServiceUserPatientProfile } from '../user-patient-profile';
import { ServiceUserPatientProfileSubscriptions } from '../user-patient-profile-subscriptions';
import { ServiceUserEmployeeProfile } from '../user-employee-profiles';
import * as dynamic from 'utils/dynamic-helpers';
import { apiClinicalMeetingActivities } from '../clinical-meeting-activities';
import { selectAuthUser } from 'store/auth';
import { RootState } from 'store';
import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import { makeChangeLog, schemaChangeLogs } from 'modules/change-log';
import { logConfig } from './log';
import { i18nAppTranslator } from 'modules/i18n';

export * from './models';

class Service extends DynamicService<FixServerObject<Components.Schemas.ClinicalMeeting, 'id'>> {
  private makePatientActive = async (input: {
    userPatientProfileID: string;
    meetingID: string;
  }) => {
    await ServiceUserPatientProfile.patch({
      appIdentityUserID: String(input.userPatientProfileID),
      isActive: true,
    });

    try {
      const { data } = await ServiceUserPatientProfileSubscriptions.getDynamicUserLatest(
        input.userPatientProfileID,
      );

      if (!data) return;

      await this.patch({ id: input.meetingID, userPatientProfileSubscriptionID: data.id });
    } catch (e) {}
  };
  create = async (data: Omit<ClinicalMeeting, 'id'>) => {
    const result = await this.post<Omit<ClinicalMeeting, 'id'>, ClinicalMeeting>(data);
    const { id, meetingFromDateTime, userPatientProfileID } = result.data;

    const { data: meetingInfo } = await this.getMeetingExtendedInformation(id);

    const {
      clinicalMeetingSubject,
      userEmployeeProfile: { userCrmProfilePermission },
    } = meetingInfo;

    const isShouldActivate = [
      clinicalMeetingSubject.isEndOfTreatmentMeeting,
      userCrmProfilePermission?.roleDoctor,
      isAfter(convertToDate(meetingFromDateTime), new Date()),
    ].every(Boolean);

    if (isShouldActivate) {
      await this.makePatientActive({ userPatientProfileID, meetingID: result.data.id });
    }

    return result;
  };
  update = async (data: PatchPartial<ClinicalMeeting, 'id' | 'userPatientProfileID'>) => {
    const result = await this.patch(data);

    if (
      data.clinicalMeetingSubjectID === CLINICAL_MEETING_SUBJECTS_IDS.END_OF_TREATMENT &&
      data.completeSessionDateTime
    ) {
      await ServiceUserPatientProfile.patch({
        appIdentityUserID: String(data.userPatientProfileID),
        isActive: false,
      });
    }

    return result;
  };
  getActivities = async (input: ClinicalMeetingGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<ClinicalMeetingGetActivitiesItem>({
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('meetingFromDateTime', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      select: [
        'id',
        'meetingFromDateTime',
        'meetingToDateTime',
        'clinicalMeetingType.title as clinicalMeetingTypeTitle',
        'clinicalMeetingSubject.title as clinicalMeetingSubjectTitle',
        'userEmployeeProfile.fullName as employee',
        'approveMeeting',
        'completeSessionDateTime',
        'arriveToClinicDateTime',
        'clinicalMeetingActivities.Count() as activities',
      ].join(','),
      orderBy: 'meetingFromDateTime desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: [item.clinicalMeetingTypeTitle, item.clinicalMeetingSubjectTitle]
        .filter(Boolean)
        .join(' '),
      date: String(item.meetingFromDateTime),
      employee: item.employee,
      download: null,

      approveMeeting: Boolean(item.approveMeeting),
      completeSessionDateTime: item.completeSessionDateTime ?? null,
      arriveToClinicDateTime: item.arriveToClinicDateTime ?? null,
      meetingFromDateTime: item.meetingToDateTime,
      meetingToDateTime: item.meetingToDateTime,

      activities: item.activities,
    }));
  };

  getExcelData = async (input: { filters: string; orderBy: string | undefined }) => {
    return this.getAllDynamic<ClinicalMeetingExcel>({
      filter: input.filters,
      orderBy: input.orderBy,
      select: [
        'id',
        'new { clinicalMeetingType.title } as clinicalMeetingType',
        'new { clinicalMeetingSubject.title } as clinicalMeetingSubject',

        'meetingFromDateTime',
        'meetingToDateTime',

        'new { userPatientProfile.fullName } as userPatientProfile',
        'new { userEmployeeProfile.fullName } as userEmployeeProfile',

        'approveMeeting',
        'remarks',
        'completeSessionDateTime',
        'arriveToClinicDateTime',

        'clinicalMeetingActivities.count() as activities',
        'userPatientProfile.userPatientProfileSubscriptions.OrderByDescending(z => z.endDate).Select(k => new {k.endDate, k.subscription.labelKey as title}).FirstOrDefault() as subscription',
      ].join(','),
    });
  };

  isMeetingLocked = <T extends Pick<ClinicalMeeting, 'arriveToClinicDateTime'>>(meeting: T) => {
    return Boolean(meeting.arriveToClinicDateTime);
  };
  isMeetingClinical = <T extends CheckClinicMeetingType>(meeting: T) => {
    return !this.isMeetingOnline(meeting);
  };
  isMeetingOnline = <T extends CheckClinicMeetingType>(meeting: T) => {
    return Boolean(meeting.clinicalMeetingType.meetingTypeKey === MEETING_TYPE_KEY.HOME);
  };

  getMeetingExtendedInformation = (meetingID: string) => {
    return this.getDynamic<GetMeetingExtendedInformationOutput>(meetingID, {
      select: dynamic.select(
        'id',
        'new { clinicalMeetingSubject.isEndOfTreatmentMeeting } as clinicalMeetingSubject',
        'new { userEmployeeProfile.userCrmProfilePermission } as userEmployeeProfile',
      ),
    });
  };

  getActiveMeetingsWithDoctorOrDietitianByRange = async (dateRange: DateValue[]) => {
    return this.getAllDynamic<MeetingsWithDoctorOrDietitianItem>({
      select: [
        'id',
        'userEmployeeProfileID',
        'userPatientProfileID',
        'userEmployeeProfile.userCrmProfilePermission.roleDoctor',
        'userEmployeeProfile.userCrmProfilePermission.roleDietitian',
      ].join(','),
      filter: dynamic
        .mergeFilters(
          dynamic.createFilterDateMoreISO('meetingFromDateTime', dateRange[0]),
          dynamic.createFilterDateLessISO('meetingToDateTime', dateRange[1]),
          'clinicalMeetingSubject.isCanceledMeeting==false',
          '(userEmployeeProfile.userCrmProfilePermission.roleDoctor==true || userEmployeeProfile.userCrmProfilePermission.roleDietitian==true)',
        )
        .join('&&'),
    });
  };
}

export const ServiceClinicalMeetings = new Service({
  mainField: 'id',
  getAll: API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC,
  post: API_CLINICAL_MEETINGS.POST,
  patch: API_CLINICAL_MEETINGS.PATCH,
  delete: API_CLINICAL_MEETINGS.DELETE,
});

export const apiClinicalMeetings = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeClinicalMeetingsByRange: builder.query<
      Pick<ClinicalMeeting, 'id' | 'meetingFromDateTime' | 'meetingToDateTime'>[],
      { userEmployeeProfileID: string; startDate: DateValue; endDate: DateValue }
    >({
      query: ({ userEmployeeProfileID, startDate, endDate }) => {
        const start = startOfDay(convertToDate(startDate));
        const end = endOfDay(convertToDate(endDate));

        return {
          url: API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC,
          params: {
            filter: [
              `userEmployeeProfileID=="${userEmployeeProfileID}"`,
              `meetingFromDateTime >= DateTime("${start.toISOString()}")`,
              `meetingToDateTime <= DateTime("${end.toISOString()}")`,
              // exclude canceled meetings
              'clinicalMeetingSubject.isCanceledMeeting==false',
            ]
              .filter(Boolean)
              .join('&&'),
            select: 'id,meetingFromDateTime,meetingToDateTime',
          },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getClinicalMeetingSendSmsToPatient: builder.query({
      queryFn: async (clinicalMeetingID: string) => {
        const { data } = await ServiceClinicalMeetings.getDynamic<ClinicalMeetingSendSmsToPatient>(
          clinicalMeetingID,
          {
            select: [
              'new { userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.mobilePhone } as patient',
              'new { userEmployeeProfile.firstName, userEmployeeProfile.lastName } as meetingEmployee',
              'userEmployeeProfileID',
              'userPatientProfileID',
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { clinicalMeetingType.meetingTypeKey } as clinicalMeetingType',
            ].join(','),
          },
        );

        const meetingFromDate = format(convertToDate(data.meetingFromDateTime), APP_FORMAT_DATE);
        const meetingFromTime = format(convertToDate(data.meetingFromDateTime), APP_FORMAT_TIME);

        const meetingToDate = format(convertToDate(data.meetingToDateTime), APP_FORMAT_DATE);
        const meetingToTime = format(convertToDate(data.meetingToDateTime), APP_FORMAT_TIME);

        return {
          data: { ...data, meetingFromTime, meetingFromDate, meetingToTime, meetingToDate },
        };
      },
    }),
    getClinicalMeetingForEndOfTreatmentConsultation: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        const {
          data: { value },
        } = await ServiceClinicalMeetings.getAllDynamic<MeetingForEndOfTreatmentConsultation>({
          select: dynamic.select(
            'id',
            'meetingFromDateTime',
            'meetingToDateTime',
            'new { clinicalMeetingSubject.title, clinicalMeetingSubject.icon, clinicalMeetingSubject.color } as subject',
            'new { userEmployeeProfile.fullName } as employee',
          ),
          filter: dynamic
            .mergeFilters(
              dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
              'clinicalMeetingSubject.isCanceledMeeting==false',
            )
            .join('&&'),
          orderBy: dynamic.orderBy('meetingFromDateTime', 'desc'),
        });

        return {
          data: value,
        };
      },
    }),
    getClinicalMeetingEndEndTreatmentByRange: builder.query({
      queryFn: async (input: { userPatientProfileID: string; endDate: DateValue }) => {
        try {
          const start = startOfDay(addMonths(convertToDate(input.endDate), -1));
          const end = startOfDay(addMonths(convertToDate(input.endDate), 1));

          const result = await ServiceClinicalMeetings.getAllDynamic({
            filter: dynamic
              .mergeFilters(
                dynamic.createFilterEquals('userPatientProfileID', input.userPatientProfileID),
                dynamic.createFilterEquals('clinicalMeetingSubject.isEndOfTreatmentMeeting', true),
                dynamic.createFilterDateMoreOrEqualsISO('meetingFromDateTime', start),
                dynamic.createFilterDateLessOrEqualsISO('meetingToDateTime', end),
              )
              .join('&&'),
            select: dynamic.select('id'),
          });

          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, error, arg) => [
        { type: RTK_TAGS.CLINICAL_MEETINGS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),
    getClinicalMeetingsFutureUnhandled: builder.query<
      Array<MeetingForEndOfTreatmentConsultation>,
      { userPatientProfileID: string }
    >({
      queryFn: async (input) => {
        try {
          const start = new Date();

          const result =
            await ServiceClinicalMeetings.getAllDynamic<MeetingForEndOfTreatmentConsultation>({
              filter: dynamic
                .mergeFilters(
                  dynamic.createFilterEquals('userPatientProfileID', input.userPatientProfileID),
                  'clinicalMeetingSubject.isCanceledMeeting==false',
                  `userPatientProfileSessionID==null`,
                  `completeSessionDateTime==null`,
                  `arriveToClinicDateTime==null`,
                  dynamic.createFilterDateMoreOrEqualsISO('meetingFromDateTime', start),
                )
                .join('&&'),
              select: dynamic.select(
                'id',
                'meetingFromDateTime',
                'meetingToDateTime',
                'new { clinicalMeetingSubject.title, clinicalMeetingSubject.icon, clinicalMeetingSubject.color } as subject',
                'new { userEmployeeProfile.fullName } as employee',
              ),
            });

          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, error, arg) => [
        { type: RTK_TAGS.CLINICAL_MEETINGS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),
    getPatientTodayClinicalMeetings: builder.query<
      PatientTodayClinicalMeeting[],
      GetPatientTodayClinicalMeetingsInput
    >({
      queryFn: async (input) => {
        try {
          const { userPatientProfileID, userEmployeeProfilePermissionID } = input;
          const now = new Date();
          const start = startOfDay(now);
          const end = endOfDay(now);
          const {
            data: { value },
          } = await ServiceClinicalMeetings.getAllDynamic<PatientTodayClinicalMeeting>({
            filter: mergeFilters(
              createFilterEquals('userPatientProfileID', userPatientProfileID),
              createFilterEquals(
                'userEmployeeProfile.userEmployeeProfilePermissionID',
                userEmployeeProfilePermissionID,
              ),
              `userPatientProfileSessionID==null`,
              'clinicalMeetingSubject.isCanceledMeeting==false',
              `meetingFromDateTime>DateTime("${start.toISOString()}")`,
              `meetingToDateTime<DateTime("${end.toISOString()}")`,
            ).join('&&'),
            select: dynamic.select(
              'id',
              'meetingFromDateTime',
              'meetingToDateTime',
              'clinicalMeetingSubjectID',
              'clinicalMeetingTypeID',
              'userEmployeeProfileID',
              'userEmployeeProfile.fullName as employee',
              'remarks',
            ),
            orderBy: 'meetingFromDateTime asc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, err, userPatientProfileID) =>
        res
          ? [
              {
                type: RTK_TAGS.CLINICAL_MEETINGS,
                id: `userPatientProfileID__${userPatientProfileID}`,
              },
              ...res.map((meeting) => ({ type: RTK_TAGS.CLINICAL_MEETINGS, id: meeting.id })),
            ]
          : [],
    }),

    createPatientTodayClinicalMeeting: builder.mutation<
      ClinicalMeeting,
      CreatePatientTodayClinicalMeeting
    >({
      queryFn: async (arg, { dispatch, getState }) => {
        try {
          const user = selectAuthUser(getState() as RootState);

          if (!user) {
            throw new Error('unexpected-behaviour');
          }

          const { userPatientProfileID } = arg;
          const requestType = dispatch(
            apiClinicalMeetingTypes.endpoints.getClinicalMeetingTypeByType.initiate(
              MEETING_TYPE_KEY.HOME,
            ),
          );
          const requestSubject = dispatch(
            apiClinicalMeetingSubjects.endpoints.getClinicalMeetingSubjectIDByType.initiate(
              CLINICAL_MEETING_SUBJECT_TYPE.OUT_OF_SCHEDULE,
            ),
          );

          const [{ id: clinicalMeetingTypeID }, clinicalMeetingSubjectID] = await Promise.all([
            requestType.unwrap(),
            requestSubject.unwrap(),
          ]);

          const meetingFromDateTime = setDateToNearestTimeSlotFlor(new Date());
          const meetingToDateTime = addMinutes(meetingFromDateTime, CALENDAR_RANGE_MINUTES);

          const newMeeting = (await dispatch(
            apiClinicalMeetings.endpoints.createClinicalMeeting.initiate({
              userPatientProfileID,
              organizerUserEmployeeProfileID: String(user.appUserID),
              clinicalMeetingSubjectID,
              clinicalMeetingTypeID,
              userEmployeeProfileID: String(user.appUserID),
              isActive: true,
              approveMeeting: false,
              remarks: '',

              meetingFromDateTime: meetingFromDateTime.toISOString(),
              meetingToDateTime: meetingToDateTime.toISOString(),
            }),
          ).unwrap()) as unknown as ClinicalMeeting;

          return { data: newMeeting };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, arg) => [
        {
          type: RTK_TAGS.CLINICAL_MEETINGS,
          id: `userPatientProfileID__${arg.userPatientProfileID}`,
        },
      ],
    }),
    attachNotebookToClinicalMeeting: builder.mutation<void, AttacheNotebookToClinicalMeetingInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          const { data: meeting } = await ServiceClinicalMeetings.getDynamic<ClinicalMeeting>(
            input.id,
          );

          await dispatch(
            apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
              initData: meeting,
              formData: {
                id: input.id,
                completeSessionDateTime:
                  input.completeSessionDateTime ||
                  meeting.completeSessionDateTime ||
                  new Date().toISOString(),
                userPatientProfileSessionID: input.userPatientProfileSessionID,
                userEmployeeProfileID: input.userEmployeeProfileID || meeting.userEmployeeProfileID,
                userPatientProfileID: String(meeting.userPatientProfileID),
                clinicalMeetingSubjectID: meeting.clinicalMeetingSubjectID,
              },
              remark: i18nAppTranslator.tp('activity-clinical-meeting-attach-to-notebook'),
              remarkForPatientCallStatusID: undefined,
            }),
          ).unwrap();

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, arg) => [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: arg.id }],
    }),

    cancelClinicalMeeting: builder.mutation<{ id: string }, CancelClinicalMeetingInput>({
      queryFn: async (arg, { dispatch, getState }) => {
        try {
          const user = selectAuthUser(getState() as RootState);

          if (!user) {
            throw new Error('unexpected-behaviour');
          }

          const { id, remarks, statusID } = arg;
          const result = dispatch(
            apiClinicalMeetingSubjects.endpoints.getClinicalMeetingSubjectIDByType.initiate(
              CLINICAL_MEETING_SUBJECT_TYPE.CANCEL,
            ),
          );
          result.unsubscribe();

          const clinicalMeetingSubjectID = await result.unwrap();

          await dispatch(
            apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
              initData: { id, clinicalMeetingSubjectID: arg.clinicalMeetingSubjectID },
              formData: { id, clinicalMeetingSubjectID },
              remark: remarks,
              remarkForPatientCallStatusID: statusID,
            }),
          ).unwrap();

          return { data: { id } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: arg.id }],
    }),
    cancelClinicalMeetingAuto: builder.mutation<{ id: string }, string>({
      queryFn: async (id, { dispatch, getState }) => {
        try {
          const result = dispatch(
            apiClinicalMeetingSubjects.endpoints.getClinicalMeetingSubjectIDByType.initiate(
              CLINICAL_MEETING_SUBJECT_TYPE.CANCEL,
            ),
          );
          result.unsubscribe();

          const { data: initData } = await ServiceClinicalMeetings.getDynamic(id, {
            select: dynamic.select('id', 'clinicalMeetingSubjectID'),
          });

          const clinicalMeetingSubjectID = await result.unwrap();

          await dispatch(
            apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
              initData: { id, clinicalMeetingSubjectID: String(initData.clinicalMeetingSubjectID) },
              formData: { id, clinicalMeetingSubjectID },
              remark: i18nAppTranslator.tp('activity-clinical-meeting-cancel'),
              remarkForPatientCallStatusID: undefined,
            }),
          ).unwrap();

          return { data: { id } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: arg }],
    }),
    setClinicalMeetingToNotShowingUp: builder.mutation<
      { id: string },
      { id: string; clinicalMeetingSubjectID: string }
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const result = dispatch(
            apiClinicalMeetingSubjects.endpoints.getClinicalMeetingSubjectIDByType.initiate(
              CLINICAL_MEETING_SUBJECT_TYPE.NOT_SHOWING_UP_MEETING,
            ),
          );
          result.unsubscribe();

          const clinicalMeetingSubjectID = await result.unwrap();

          await dispatch(
            apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
              initData: {
                id: input.id,
                clinicalMeetingSubjectID: input.clinicalMeetingSubjectID,
              },
              formData: {
                id: input.id,
                clinicalMeetingSubjectID,
              },
              remark: i18nAppTranslator.tp('activity-clinical-meeting-not-showing-up'),
              remarkForPatientCallStatusID: undefined,
            }),
          ).unwrap();

          return { data: { id: input.id } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: arg.id }],
    }),

    patchClinicalMeeting: builder.mutation({
      queryFn: async (input: PatchPartial<ClinicalMeeting, 'id'>, { dispatch }) => {
        try {
          await ServiceClinicalMeetings.patch(input);

          return { data: input };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [
        ...(arg.userPatientProfileID
          ? [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: `patient__${res.userPatientProfileID}` }]
          : []),
        { type: RTK_TAGS.CLINICAL_MEETINGS, id: arg.id },
      ],
    }),
    updateClinicalMeeting: builder.mutation<
      void,
      PatchPartial<ClinicalMeeting, 'id' | 'userPatientProfileID' | 'userEmployeeProfileID'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          await ServiceClinicalMeetings.patch(input);

          try {
            await dispatch(
              apiClinicalMeetings.endpoints.clinicalMeetingTryDeactivatePatient.initiate(input),
            );
          } catch {}

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: arg.id }],
    }),
    updateClinicalMeetingWithLog: builder.mutation<
      void,
      {
        initData: PatchPartial<ClinicalMeeting, 'id'>;
        formData: PatchPartial<ClinicalMeeting, 'id'>;
        remark: Unset;
        remarkForPatientCallStatusID: Unset;
      }
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const { initData, formData, remark, remarkForPatientCallStatusID } = input;

          try {
            const fields = await makeChangeLog(logConfig, {
              formData,
              initData,
              getDefinition: (_, params) =>
                ServiceClinicalMeetings.getDynamic<Components.Schemas.ClinicalMeeting>(
                  initData.id,
                  params,
                ).then(({ data }) => data),
              update: (formData) => ServiceClinicalMeetings.patch(formData),
            });

            const changes = schemaChangeLogs.cast(
              { fields },
              { stripUnknown: true, assert: false },
            );

            await dispatch(
              apiClinicalMeetingActivities.endpoints.postClinicalMeetingActivity.initiate({
                clinicalMeetingID: initData.id,
                remarks: remark,
                remarkForPatientCallStatusID,
                changes: JSON.stringify(changes),
              }),
            );

            await dispatch(
              apiClinicalMeetings.endpoints.clinicalMeetingTryDeactivatePatient.initiate(
                input.formData,
              ),
            );
          } catch {}

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [
        { type: RTK_TAGS.CLINICAL_MEETINGS, id: arg.initData.id },
      ],
    }),

    createClinicalMeeting: builder.mutation<ClinicalMeeting, Omit<ClinicalMeeting, 'id'>>({
      queryFn: async (input, { dispatch }) => {
        try {
          const { data } = await ServiceClinicalMeetings.create(input);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [
        ...(res
          ? [{ type: RTK_TAGS.CLINICAL_MEETINGS, id: `patient__${res.userPatientProfileID}` }]
          : []),
      ],
    }),
    clinicalMeetingTryDeactivatePatient: builder.mutation<
      boolean,
      PatchPartial<ClinicalMeeting, 'id'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const isEndOfTreatment =
            input.clinicalMeetingSubjectID === CLINICAL_MEETING_SUBJECTS_IDS.END_OF_TREATMENT;

          if (!isEndOfTreatment) {
            return { data: false };
          }
          if (!input.completeSessionDateTime) {
            return { data: false };
          }

          const { data: meeting } =
            await ServiceClinicalMeetings.getDynamic<TryDeactivatePatientMeetingData>(input.id, {
              select: dynamic.select(
                'id',
                'userEmployeeProfileID',
                'userPatientProfileID',
                `new { userPatientProfile.isActive } as patient`,
                `new { userEmployeeProfile.fullName } as employee`,
              ),
            });

          if (meeting.patient.isActive === false) {
            return { data: false };
          }

          const [{ data: permissions }, { data: subscription }] = await Promise.all([
            ServiceUserEmployeeProfile.getPermissions(String(meeting.userEmployeeProfileID)),
            ServiceUserPatientProfileSubscriptions.getDynamicUserLatest<{
              id: string;
              endDate: string;
            }>(String(meeting.userPatientProfileID), {
              select: ['id', 'endDate'].join(','),
            }),
          ]);

          if (!permissions.roleDoctor) {
            return { data: false };
          }

          const endDate = convertToDate(subscription.endDate);

          const dif = differenceInMonths(endDate, new Date());

          if (dif >= 1) {
            return { data: false };
          }

          await dispatch(
            apiUserPatientProfile.endpoints.patchPatientWithLog.initiate({
              initData: {
                appIdentityUserID: String(meeting.userPatientProfileID),
                isActive: !!meeting.patient.isActive,
              },
              formData: {
                appIdentityUserID: String(meeting.userPatientProfileID),
                isActive: false,
              },
              remark: i18nAppTranslator.tp('activity-patient-update-end-of-treatment', {
                employee: meeting.employee,
              }),
            }),
          ).unwrap();

          return { data: true };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
