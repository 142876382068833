import { Notebook } from '../notebook';
import { DateValue } from 'utils/dates';
import { FixServerObject } from 'utils/types';
import { UserPatientProfileSessionItem } from 'services/user-patient-profile-session-item';
import { UserPatientProfileSessionClinicDrug } from 'services/user-patient-profile-session-clinic-drugs';
import { UserEmployeeProfile } from 'services/user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileSessions';

export const API_USER_PATIENT_PROFILE_SESSIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  GET_ALL_DYNAMIC_POST: `${API_ENDPOINT}/GetAllDynamicFromPost`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileSession, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSession, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSession
  extends Omit<FixServerObject<Components.Schemas.UserPatientProfileSession, 'id'>, 'entryDate'> {
  entryDate?: string | undefined | Date;
  createdDate?: string | undefined | null;
}

export interface UserSessionsGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}
export interface UserSessionsGetActivitiesItem
  extends Pick<UserPatientProfileSession, 'id' | 'entryDate'> {
  title: Notebook['labelKey'];
  employeeFullName: string;
}

export interface SessionItem
  extends Pick<
    UserPatientProfileSessionItem,
    'id' | 'externalSystemValue' | 'internalSystemValue' | 'fieldInputID'
  > {}
export interface SessionForNotebookGenerator
  extends Pick<
    UserPatientProfileSession,
    | 'id'
    | 'createdDate'
    | 'userPatientProfileSessionItems'
    | 'icd10Remarks'
    | 'sideEffectRemarks'
    | 'surgeryRemarks'
    | 'sensitivityRemarks'
    | 'sensitivityDrugRemarks'
    | 'routineDrugRemarks'
    | 'pastDrugForObesityRemarks'
  > {
  userPatientProfileSessionItems: SessionItem[];
  icd10IDs: string[];
  surgeryIDs: string[];
  sensitivityIDs: string[];

  sensitivityDrugIDs: string[];
  regularDrugIDs: string[];
  pastDrugIDs: string[];
  sideEffectIDs: string[];
}

interface PreviewItem {
  id: string;
  title: string;
}

export interface NotebookPreview {
  icd10PreviewItems: PreviewItem[];
  surgeryPreviewItems: PreviewItem[];
  sensitivityPreviewItems: PreviewItem[];
  regularDrugPreviewItems: PreviewItem[];
  pastDrugPreviewItems: PreviewItem[];
  sideEffectPreviewItems: PreviewItem[];
  sensitivityDrugPreviewItems: PreviewItem[];
}
interface ClinicalDrugPreviewItem
  extends Pick<Required<UserPatientProfileSessionClinicDrug>, 'id' | 'dosageForm' | 'remarks'> {
  catalogName: string;
}
interface SessionItemPreviewItem {
  id: string;
  labelKey: string;
  entryValueString: string;
  isUrgent: boolean;
}
export interface SessionForNotebookGeneratorPreview
  extends Pick<
      UserPatientProfileSession,
      | 'id'
      | 'createdDate'
      | 'icd10Remarks'
      | 'sideEffectRemarks'
      | 'surgeryRemarks'
      | 'sensitivityRemarks'
      | 'sensitivityDrugRemarks'
      | 'routineDrugRemarks'
      | 'pastDrugForObesityRemarks'
      | 'supportMeetings'
    >,
    NotebookPreview {
  notebook: Pick<Notebook, 'labelKey' | 'isForPrescriptionOnly'>;
  userEmployeeProfile: Pick<UserEmployeeProfile, 'fullName'>;
  clinicalDrugs: ClinicalDrugPreviewItem[];
  sessionItems: SessionItemPreviewItem[];
}

export interface PostOrPatchNotebookGeneratorInput {
  userPatientProfileSessionID: string | null;
  notebookID: string;
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  userPatientProfileSessionItems: (UserPatientProfileSessionItem & { formValue: any })[];
  icd10IDs: string[] | undefined;
  surgeryIDs: string[] | undefined;
  sensitivityIDs: string[] | undefined;
  regularDrugIDs: string[] | undefined;
  sensitivityDrugIDs: string[] | undefined;
  pastDrugIDs: string[] | undefined;
  sideEffectIDs: string[] | undefined;
}

export interface CloneClinicalDrugsFromPreviousNotebookInput {
  userPatientProfileID: string;
  userPatientProfileSessionID: string;
}

export interface CloneClinicalDrugsFromPreviousNotebookItem
  extends Pick<UserPatientProfileSession, 'id' | 'userPatientProfileSessionClinicDrugs'> {
  userPatientProfileSessionClinicDrugs: Pick<
    UserPatientProfileSessionClinicDrug,
    'drugID' | 'dosageForm'
  >[];
}

export interface PostOrPatchNotebookGeneratorResult {
  userPatientProfileSessionID: string;
}

export interface LatestSessionWithItems
  extends Pick<
    UserPatientProfileSession,
    'id' | 'createdDate' | 'notebook' | 'userEmployeeProfile'
  > {
  remarks: string;
  notebook: Pick<Notebook, 'labelKey'>;
  userEmployeeProfile: Pick<UserEmployeeProfile, 'fullName'>;
  previewItems: { id: string; title: string }[];
}
export interface SessionWithItems
  extends Pick<
    UserPatientProfileSession,
    'id' | 'createdDate' | 'notebook' | 'userEmployeeProfile'
  > {
  remarks: string;
  notebook: Pick<Notebook, 'labelKey'>;
  userEmployeeProfile: Pick<UserEmployeeProfile, 'fullName'>;
  previewItems: { id: string; title: string; updatedDate: string; isActive: boolean }[];
}

export interface SessionsPatientDeclaration
  extends Pick<UserPatientProfileSession, 'doctorViewed' | 'entryDate'> {
  sessionItems: SessionItemPreviewItem[];
}
