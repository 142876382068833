import React, { useCallback } from 'react';
import { useCurrentUser } from 'components/hooks';
import { UserPatientProfileDietDiary } from 'services/user-patient-profile-diet-diaries';
import EditorString from 'components/data-grid/components/column/editors/editor-string';
import { IDataGridColumnEditorCustom } from 'components/data-grid/model';

interface IEditor extends Omit<IDataGridColumnEditorCustom, 'field'> {
  field: keyof UserPatientProfileDietDiary;
}
interface Props {
  row: UserPatientProfileDietDiary;
  editor: IEditor;
  data: any;
  onTasks: (value: any) => void;
  onSubmit: (value: any) => void;
  onClose: () => void;
}
export const ColumnReply: React.FC<Props> = ({ row, editor, data, onTasks, onClose }) => {
  const { appUserID } = useCurrentUser();

  const onHandleSubmit = useCallback(
    (value: { data: string }) => {
      const { field } = editor;
      if (field && row[field] !== value.data) {
        onTasks([
          [
            'pathData',
            {
              row: { ...row },
              value: {
                [field]: value.data,
                isViewed: true,
                userEmployeeProfileID: appUserID,
                replyDate: new Date().toISOString(),
              },
            },
          ],
        ]);
        onClose();
      }
    },
    [editor, onTasks, onClose, appUserID, row],
  );
  return (
    <EditorString
      // @ts-ignore
      row={row}
      // @ts-ignore
      editor={editor}
      data={data}
      onSubmit={onHandleSubmit}
      onClose={onClose}
    />
  );
};
