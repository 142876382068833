import React from 'react';
import { IGridFormProps } from 'components/models';
import { Notebook } from 'services/notebook';
import { validateRule } from 'AurionCR/components/formV2';
import { Grid } from '@material-ui/core';
import { useSourcePermissions } from 'components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import { AppCheckbox } from 'components/app-checkbox';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';

interface FormModel extends Notebook {
  userEmployeeProfilePermissionIDs: string[];
}

export const MainFormFields: React.FC<IGridFormProps<FormModel> & { formIsNew: boolean }> = ({
  isLoading,
  control,
  errors,
  formIsNew,
}) => {
  const { t } = useTranslate();
  const sourcePermissions = useSourcePermissions();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const renderCheckbox = (renderProps: ControllerRenderProps<FormModel>) => {
    return <AppCheckbox {...getFieldProps(renderProps)} disabled={isLoading} />;
  };

  return (
    <div className="form-gender">
      <Grid container spacing={5}>
        <Grid item md={formIsNew ? 12 : 6}>
          <Controller
            control={control}
            name={'labelKey'}
            rules={validateRule('required,minLength')}
            render={(renderProps) => {
              return (
                <AppInput
                  {...getFieldProps(renderProps)}
                  label={t('title')}
                  autoFocus
                  disabled={isLoading}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={formIsNew ? 12 : 6}>
          <Controller
            control={control}
            name={'externalSystemTitle'}
            render={(renderProps) => {
              return (
                <AppInput
                  {...getFieldProps(renderProps)}
                  autoFocus
                  disabled={isLoading}
                  disableClearable={!formIsNew}
                  inputProps={{ readOnly: !formIsNew }}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={12}>
          <Controller
            control={control}
            name={'userEmployeeProfilePermissionIDs'}
            rules={validateRule('required')}
            render={(renderProps) => {
              return (
                <AppSelect
                  {...getFieldProps(renderProps)}
                  label={t('role')}
                  options={sourcePermissions.data}
                  loading={sourcePermissions.loading}
                  disabled={isLoading}
                  multiple
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Controller name={'migatedFromOldSystem'} control={control} render={renderCheckbox} />
      <Controller name={'isForPatient'} control={control} render={renderCheckbox} />
      <Controller name={'displayNextMeetingForm'} control={control} render={renderCheckbox} />
      <Controller name={'displayIcd10Section'} control={control} render={renderCheckbox} />
      <Controller name={'displaySideEffectSection'} control={control} render={renderCheckbox} />
      <Controller
        name={'displayPastDrugForObesitySection'}
        control={control}
        render={renderCheckbox}
      />
      <Controller name={'displayRoutineDrugSection'} control={control} render={renderCheckbox} />
      <Controller name={'displayClinicDrugSection'} control={control} render={renderCheckbox} />
      <Controller name={'displaySurgerySection'} control={control} render={renderCheckbox} />
      <Controller name={'displaySensitivitySection'} control={control} render={renderCheckbox} />
      <Controller
        name={'displaySensitivityDrugSection'}
        control={control}
        render={renderCheckbox}
      />
      <Controller name={'isForWeightOnly'} control={control} render={renderCheckbox} />
      <Controller name={'isForPrescriptionOnly'} control={control} render={renderCheckbox} />
      <Controller name={'isForClinicalMeeting'} control={control} render={renderCheckbox} />
      <Controller name={'isForTraining'} control={control} render={renderCheckbox} />
      <Controller name={'isRequirePrescription'} control={control} render={renderCheckbox} />
      <Controller name={'isAllowToEditByAll'} control={control} render={renderCheckbox} />
      <Controller name={'isActive'} control={control} render={renderCheckbox} />
    </div>
  );
};
