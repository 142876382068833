import React, { FC, memo, useMemo } from 'react';
import { IDataGridColumnEditorString } from '../../../model';
import { Controller, useForm } from 'react-hook-form';
import { ClickAwayListener } from '@material-ui/core';
import Footer from './footer';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';
import { object, string } from 'yup';
import { transformToOptimizedString } from 'utils/transformers';
import { yupResolver } from '@hookform/resolvers/yup';

const makeSchema = (required: boolean) => {
  if (required) {
    return object({
      data: string()
        .typeError('rule-string')
        .required('rule-required')
        .transform(transformToOptimizedString)
        .default(''),
    });
  }

  return object({
    data: string()
      .typeError('rule-string')
      .nullable()
      .notRequired()
      .transform(transformToOptimizedString)
      .default(''),
  });
};

interface Props {
  editor: IDataGridColumnEditorString;
  data: string;
  onClose: () => void;
  onSubmit: (formData: { data: string }) => void;
}

const EditorString: FC<Props> = ({ editor, data, onClose, onSubmit }) => {
  const { required } = editor;
  const schema = useMemo(() => {
    return makeSchema(!!required);
  }, [required]);

  const { handleSubmit, errors, control } = useForm({
    defaultValues: { data: data || '' },
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor string">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="data"
            control={control}
            render={(renderProps) => {
              return (
                <AppInput
                  {...getFieldProps(renderProps)}
                  autoFocus
                  fullWidth
                  disableClearable
                  label={null}
                />
              );
            }}
          />
          <Footer onClose={onClose} />
        </form>
      </div>
    </ClickAwayListener>
  );
};

export default memo(EditorString);
