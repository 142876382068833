import React, { useCallback, useState } from 'react';
import { useEffectError } from 'hooks';
import { NotebookFormGenerator, NotebookFormGeneratorProps } from '../notebook-form-generator';
import { DialogSelectClinicalMeetings } from './components/dialog-select-meeting';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { isMutationFulfilled, isMutationRejected } from '../../../../utils/rtk-query';
import { useAttachNotebookToClinicalMeeting } from '../../../../hooks/use-attach-notebook-to-clinical-meeting';
import { useCurrentUser } from '../../../hooks';

const useCreateNewMeeting = apiClinicalMeetings.useCreatePatientTodayClinicalMeetingMutation;

type Session = {
  id: string;
};

interface Props extends NotebookFormGeneratorProps {}
enum STEP {
  MEETINGS = 1,
  FOM = 2,
}

export const NotebookFormGeneratorClinicalMeetingBefore: React.FC<Props> = ({
  notebookID,
  userPatientProfileID,
  supportMeetingTypeID,
  onClose,
  onDone: _onDone,
  onCreated,
  showDelete = true,
}) => {
  const { appUserID } = useCurrentUser();
  const [triggerNewMeeting, resultNewMeeting] = useCreateNewMeeting();
  useEffectError(resultNewMeeting.error);

  const [{ handleDone, handleCancel, handleStart }, resultAttach] =
    useAttachNotebookToClinicalMeeting();

  const [step, setStep] = useState<STEP>(STEP.MEETINGS);
  const [meetingID, setMeetingID] = useState<string | undefined>(undefined);

  const onDoneWithEmpty = useCallback(
    async (session: Session) => {
      const res = await triggerNewMeeting({ userPatientProfileID });

      if (isMutationRejected(res)) {
        return res;
      }

      return handleDone({
        id: res.data.id,
        userPatientProfileSessionID: session.id,
        userEmployeeProfileID: String(appUserID),
      });
    },
    [triggerNewMeeting, userPatientProfileID, handleDone, appUserID],
  );
  const onDoneWithExisting = useCallback(
    async (session: Session) => {
      if (!meetingID) {
        throw new Error('Notebook: unexpected-behaviour');
      }
      return handleDone({
        id: meetingID,
        userPatientProfileSessionID: session.id,
        userEmployeeProfileID: String(appUserID),
      });
    },
    [meetingID, handleDone, appUserID],
  );

  const onDone = useCallback(
    async (session: Session) => {
      const res = await (meetingID ? onDoneWithExisting(session) : onDoneWithEmpty(session));

      if (isMutationFulfilled(res)) {
        _onDone(session);
      }
    },
    [meetingID, onDoneWithExisting, onDoneWithEmpty, _onDone],
  );

  const onContinueWithMeeting = useCallback(
    (meeting: { id: string }) => {
      setMeetingID(meeting.id);
      handleStart(meeting);
      setStep(STEP.FOM);
    },
    [handleStart],
  );
  const onContinueEmpty = useCallback(() => {
    setMeetingID(undefined);
    setStep(STEP.FOM);
  }, []);

  const onCloseNotebookForm = useCallback(async () => {
    if (!meetingID) {
      return onClose();
    }
    const res = await handleCancel({ id: meetingID });

    if (isMutationFulfilled(res)) {
      onClose();
    }
  }, [handleCancel, meetingID, onClose]);

  if (step === STEP.MEETINGS) {
    return (
      <DialogSelectClinicalMeetings
        userPatientProfileID={userPatientProfileID}
        onClose={onClose}
        onContinueWithMeeting={onContinueWithMeeting}
        onContinueEmpty={onContinueEmpty}
      />
    );
  }

  return (
    <NotebookFormGenerator
      isLoading={resultAttach.isLoading}
      notebookID={notebookID}
      onClose={onCloseNotebookForm}
      onCreated={onCreated}
      supportMeetingTypeID={supportMeetingTypeID}
      userPatientProfileID={userPatientProfileID}
      showDelete={showDelete}
      onDone={onDone}
      meetingFromDateTime={undefined}
      meetingToDateTime={undefined}
    />
  );
};
