import { DataGridLightTable } from '../data-grid-table';
import { DataGridLightSettingsProvider } from '../provider-settings';
import { DataGridLightClasses, DataGridLightColumn, DataGridLightRowProps } from '../../models';
import React from 'react';

interface Props<T> {
  className?: string;
  columns: DataGridLightColumn<T, any>[];
  rows: T[];
  rowKey?: keyof T;
  rowProps?: DataGridLightRowProps<T>;
  classes?: Partial<DataGridLightClasses>;
}

export const DataGridLight = <T,>({ columns, rows, rowProps, rowKey, classes }: Props<T>) => {
  return (
    <DataGridLightSettingsProvider classes={classes}>
      <DataGridLightTable columns={columns} rows={rows} rowKey={rowKey} rowProps={rowProps} />
    </DataGridLightSettingsProvider>
  );
};
