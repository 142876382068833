import { useMemo } from 'react';
import { useSource } from 'AurionCR/components';
import { API_MANUFACTURES, Manufacturer } from 'services/manufacturers';
import { API_REGISTRATION_OWNERS, RegistrationOwner } from 'services/registration-owner';
import { API_LANGUAGES, iLanguage } from 'services/languages';
import { generateDynamicQuery } from 'utils/service';
import { API_USER_PATIENT_PROFILE, UserPatientProfile } from 'services/user-patient-profile';
import { API_USER_PATIENT_PROFILE_PRESCRIPTIONS } from 'services/user-patient-profile-prescriptions';
import { API_PHARMACIES, Pharmacy } from 'services/pharmacies';
import { API_CLINICAL_MEETING_TYPES, ClinicalMeetingType } from 'services/clinical-meeting-types';
import {
  API_FORM_DOCUMENT_CATEGORIES,
  iFormDocumentCategory,
} from 'services/form-document-categories';
import {
  API_CLINICAL_MEETING_SUBJECTS,
  ClinicalMeetingSubject,
} from 'services/clinical-meeting-subjects';
import {
  API_DASHBOARD_REPORTS,
  IDashboardClinicalMeetingAnalytics,
  IDashboardGenders,
  IDashboardMeetings,
  IDashboardPatients,
  IDashboardRanges,
  IDashboardSubscriptionsAnalytics,
  IDashboardSupportMeetingAnalytics,
} from 'services/dashboard-reports';
import {
  API_REMARK_FOR_PATIENT_CALL_STATUSES,
  RemarkForPatientCallStatus,
} from 'services/remark-for-patient-call-status';
import { API_USER_EMPLOYEE_PROFILES, UserEmployeeProfile } from 'services/user-employee-profiles';
import { API_CLINICAL_MEETINGS } from 'services/clinical-meetings';
import { API_SUPPORT_MEETINGS } from 'services/support-meetings';
import {
  API_USER_EMPLOYEE_PROFILE_PERMISSIONS,
  PERMISSION_IDS,
} from 'services/user-employee-profile-permissions';
import { API_SUPPORT_MEETING_TYPES, SupportMeetingType } from 'services/support-meeting-types';
import { API_FORM_DOCUMENTS, FormDocument } from 'services/form-documents';
import { API_NOTEBOOKS } from 'services/notebook';
import { enumToArray, fieldToLabelKey } from 'utils/other';
import {
  ActionKey,
  API_TODO_TASK_CATEGORIES,
  CONFIG_CATEGORY_ACTION,
  TaskTag,
  ToDoTaskCategory,
} from 'services/todo-task-categories';
import { API_DIAGNOSIS_TYPES, IDiagnosisType } from 'services/diagnosis-types';
import { API_BMI_SUMMARIES, IBMISummary } from 'services/bmi-summaries';
import { useCurrentUser } from 'components/hooks/use-current-user';
import { API_GENDERS } from 'services/genders';
import { API_LEAD_ACTIVITY_TYPES, LeadActivityType } from 'services/lead-activity-type';
import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import { API_DIET_DIARY_TYPES } from 'services/diet-diary-types';
import { useTranslate } from 'hooks/use-translate';
import { API_PRESCRIPTION_STEPS, PrescriptionStep } from 'services/prescription-steps';
import { API_CITIES } from 'services/cities';
import { API_SUBSCRIPTIONS } from 'services/subscription';
import { API_INPUT_TYPES, InputType } from 'services/input-type';
import { useDateLocale } from '../../contexts/picker-provider';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useMap } from '../../hooks';
import { Unset } from '../../utils/types';

export const useSourcePrescriptionSteps = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: 'isActive==true',
      select: 'id,title,isActive,stepTypeKey,icon,color,rank',
      orderBy: 'rank asc',
    });
  }, []);

  return useSource<
    Pick<PrescriptionStep, 'id' | 'title' | 'stepTypeKey' | 'icon' | 'color' | 'rank'>
  >(`${API_PRESCRIPTION_STEPS.GET_ALL_DYNAMIC}?${query}`, 'id', true);
};

export const useSourcesLanguages = () => {
  return useSource<iLanguage>(
    `${API_LANGUAGES.GET_ALL_DYNAMIC}?select=id,title,direction`,
    'id',
    true,
  );
};

export const useSourcesManufacturers = () => {
  return useSource<Manufacturer>(
    `${API_MANUFACTURES.GET_ALL_DYNAMIC}?select=id,manufactureName as title&filter=isActive==true&orderBy=manufactureName`,
    'id',
  );
};
export const useSourcesRegistarationOwners = () => {
  return useSource<RegistrationOwner>(
    `${API_REGISTRATION_OWNERS.GET_ALL_DYNAMIC}?select=id,regOwnerName as title&filter=isActive==true&orderBy=regOwnerName`,
    'id',
  );
};

export const useSourceDoctors = (isActiveOnly: boolean = true) => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(
        createFilterEquals('userEmployeeProfilePermissionID', PERMISSION_IDS.DOCTOR),
        isActiveOnly && `isActive==true`,
      ).join('&&'),
      select:
        'appIdentityUserID as id,fullName as title,firstName,lastName,userPhoto,userEmployeeProfilePermissionID,isActive',
      orderBy: 'firstName,lastName asc',
    });
  }, [isActiveOnly]);
  return useSource(`${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?${query}`, 'id', true);
};
export const useSourceDietitians = (isActiveOnly: boolean = true) => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(
        createFilterEquals('userEmployeeProfilePermissionID', PERMISSION_IDS.DIETITIAN),
        isActiveOnly && `isActive==true`,
      ).join('&&'),
      select:
        'appIdentityUserID as id,fullName as title,userEmployeeProfilePermissionID,userPhoto,isActive',
      orderBy: 'firstName,lastName asc',
    });
  }, [isActiveOnly]);
  return useSource<{ id: string; title: string }>(
    `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};
export const useSourceProfilesSupport = (isActiveOnly: boolean = true) => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(
        createFilterEquals('userCrmProfilePermission.rolePatientManager', true),
        isActiveOnly && `isActive==true`,
      ).join('&&'),
      select:
        'appIdentityUserID as id,fullName as title,userEmployeeProfilePermissionID,firstName,lastName,userPhoto,isActive,',
      orderBy: 'firstName,lastName asc',
    });
  }, [isActiveOnly]);

  return useSource<{ id: string; title: string }>(
    `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};

export type SourceEmployeeItem = Pick<
  UserEmployeeProfile,
  | 'isActive'
  | 'userEmployeeProfilePermissionID'
  | 'userPhoto'
  | 'firstName'
  | 'lastName'
  | 'signature'
> & { title: string; uid: string; id: string };

export const useSourceEmployees = (isActiveOnly: boolean = true) => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(isActiveOnly && `isActive==true`).join('&&'),
      select:
        'appIdentityUserID as id,userPhoto,fullName as title,firstName,lastName,userEmployeeProfilePermissionID,id as uid,isActive,signature',
      orderBy: 'firstName,lastName asc',
    });
  }, [isActiveOnly]);

  return useSource<SourceEmployeeItem>(
    `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};

export const useSourceTodoActions = () => {
  const { t } = useTranslate();
  const data = enumToArray(ActionKey).map((item) => ({
    ...item,
    title: t(fieldToLabelKey(item.title)),
  }));
  return { data };
};
export const useSourceTodoTags = () => {
  const { t } = useTranslate();
  const data = enumToArray(TaskTag).map((item) => ({
    ...item,
    title: t(fieldToLabelKey(item.title)),
  }));
  return { data };
};

export const useSourcePatients = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select: ['appIdentityUserID as id', 'firstName', 'lastName', 'idNumber', 'isActive'].join(
        ',',
      ),
      orderBy: 'firstName,lastName asc',
    });
  }, []);
  return useSource<
    Pick<UserPatientProfile, 'firstName' | 'lastName' | 'idNumber'> & {
      id: string;
    }
  >(`${API_USER_PATIENT_PROFILE.GET_ALL_DYNAMIC}?${query}`, 'id', false);
};

export const useSourceDietDiaryTypes = () => {
  return useSource<{ id: string; title: string }>(
    `${API_DIET_DIARY_TYPES.GET_ALL_DYNAMIC}?Select=id,title&filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourceClinicalMeetingTypes = () => {
  return useSource<Pick<ClinicalMeetingType, 'id' | 'title' | 'icon' | 'color' | 'meetingTypeKey'>>(
    `${API_CLINICAL_MEETING_TYPES.GET_ALL_DYNAMIC}?select=id,title,color,icon,meetingTypeKey&filter=isActive==true&orderBy=rank`,
    'id',
    true,
  );
};
export const useSourceClinicalMeetingSubjects = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select:
        'id,title,color,icon,isActive,defaultDuration,defaultDurationNotDoctor,nextMeetingGapDuration,isFirstMeeting',
      filter: 'isActive==true',
      orderBy: 'rank asc',
    });
  }, []);
  return useSource<
    Pick<
      ClinicalMeetingSubject,
      | 'id'
      | 'title'
      | 'icon'
      | 'color'
      | 'defaultDuration'
      | 'defaultDurationNotDoctor'
      | 'nextMeetingGapDuration'
      | 'isFirstMeeting'
    >
  >(`${API_CLINICAL_MEETING_SUBJECTS.GET_ALL_DYNAMIC}?${query}`, 'id', true);
};
export const useSourceSupportMeetingTypes = () => {
  return useSource<
    Pick<
      SupportMeetingType,
      'id' | 'title' | 'icon' | 'color' | 'isActive' | 'isPrescriptionRenewal'
    >
  >(
    `${API_SUPPORT_MEETING_TYPES.GET_ALL_DYNAMIC}?select=id,title,color,icon,isActive,isPrescriptionRenewal&orderBy=rank asc`,
    'id',
    true,
  );
};

export const useSourceDashboardSubscriptionAnalytics = () => {
  return useSource<IDashboardSubscriptionsAnalytics>(
    API_DASHBOARD_REPORTS.GET_SUBSCRIPTIONS_ANALYTICS,
  );
};
export const useSourceDashboardSupportMeetingAnalytics = () => {
  return useSource<IDashboardSupportMeetingAnalytics>(
    API_DASHBOARD_REPORTS.GET_SUPPORT_MEETING_ANALYTICS,
  );
};
export const useSourceDashboardClinicalMeetingAnalytics = () => {
  return useSource<IDashboardClinicalMeetingAnalytics>(
    API_DASHBOARD_REPORTS.GET_CLINIC_MEETING_ANALYTICS,
  );
};

export const useSourceDashboardPatientGender = () => {
  return useSource<IDashboardGenders>(API_DASHBOARD_REPORTS.GET_PATIENT_GENDERS_KPI);
};

export const useSourceDashboardPatientAge = () => {
  return useSource<IDashboardRanges>(API_DASHBOARD_REPORTS.GET_PATIENT_AGE_RANGES_KPI);
};

export const useSourceDashboardMeetings = () => {
  return useSource<IDashboardMeetings>(API_DASHBOARD_REPORTS.GET_MEETING_TYPES_KPI);
};

export const useSourceDashboardPatientsKpi = () => {
  return useSource<IDashboardPatients>(API_DASHBOARD_REPORTS.GET_PATIENTS_KPI);
};
export const useSourcePharmacies = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select: Object.keys(new Pharmacy()).join(','),
      filter: 'isActive==true',
      orderBy: 'name asc',
    });
  }, []);
  return useSource<Pharmacy>(`${API_PHARMACIES.GET_ALL_DYNAMIC}?${query}`, 'id', true);
};
export const useSourceDiagnosisTypes = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select: 'id,title',
      filter: 'isActive==true',
      orderBy: 'title asc',
    });
  }, []);
  return useSource<Pick<IDiagnosisType, 'id' | 'title'>>(
    `${API_DIAGNOSIS_TYPES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};
export const useSourceBmiSummaries = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select: 'id,title',
      filter: 'isActive==true',
      orderBy: 'title asc',
    });
  }, []);
  return useSource<Pick<IBMISummary, 'id' | 'title'>>(
    `${API_BMI_SUMMARIES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};

interface PrescriptionLatestOptions {
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: Unset;
}

export const useSourcesPrescriptionLatest = (options: PrescriptionLatestOptions) => {
  const queryParams = useMemo(
    () =>
      generateDynamicQuery({
        select: 'id,userPatientProfileID,entryDate',

        filter: [
          options.userPatientProfilePrescriptionID &&
            `id!="${options.userPatientProfilePrescriptionID}"`,
          `userPatientProfileID=="${options.userPatientProfileID}"`,
          'isActive==true',
        ]
          .filter(Boolean)
          .join('&&'),
        orderBy: 'entryDate desc',
        take: 1,
      }),
    [options.userPatientProfileID, options.userPatientProfilePrescriptionID],
  );
  return useSource<{ id: string; entryDate: string }>(
    `${API_USER_PATIENT_PROFILE_PRESCRIPTIONS.GET_ALL_DYNAMIC}?${queryParams}`,
    'id',
    true,
  );
};

export const useSourcePatientRemarkStatuses = () => {
  return useSource<
    Pick<
      RemarkForPatientCallStatus,
      'id' | 'title' | 'isReassigned' | 'isRescheduled' | 'isAuto' | 'isCanceledMeeting'
    >
  >(
    `${API_REMARK_FOR_PATIENT_CALL_STATUSES.GET_ALL_DYNAMIC}?select=id,title,isRescheduled,isReassigned,isCanceledMeeting,isAuto,isActive&orderBy=rank&filter=isActive==true`,
    'id',
    true,
  );
};
export const useSourceDocumentTemplates = () => {
  return useSource<
    Pick<FormDocument, 'id' | 'title' | 'formDocumentCategoryID'> &
      Pick<Components.Schemas.FormDocumentCategory, 'useForContract'>
  >(
    `${API_FORM_DOCUMENTS.GET_ALL_DYNAMIC}?select=id,title,formDocumentCategoryID,formDocumentCategory.useForContract as useForContract&orderBy=title&filter=isActive==true`,
    'id',
    true,
  );
};

export const useSourceDocumentCategories = () => {
  return useSource<Pick<iFormDocumentCategory, 'id' | 'title' | 'useForDrug' | 'useForContract'>>(
    `${API_FORM_DOCUMENT_CATEGORIES.GET_ALL_DYNAMIC}?select=id,title,useForDrug,useForContract&orderBy=title&filter=isActive==true`,
    'id',
    true,
  );
};

const useSourceNotebooks = () => {
  const { userEmployeeProfilePermission, $isAdmin } = useCurrentUser();
  const userEmployeeProfilePermissionID = userEmployeeProfilePermission?.id;
  const urlSourceNotebook = useMemo(() => {
    return [
      API_NOTEBOOKS.GET_ALL_DYNAMIC,
      generateDynamicQuery({
        select: 'id,labelKey as title,displayNextMeetingForm,isForClinicalMeeting',
        orderBy: 'labelKey asc',
        filter: mergeFilters(
          'isForPatient==false',
          'migatedFromOldSystem==false',
          'isForPrescriptionOnly==false',
          'isActive==true',
          !$isAdmin &&
            `userEmployeeProfilePermissionNotebooks.any(k => k.userEmployeeProfilePermissionID == "${userEmployeeProfilePermissionID}")`,
        ).join('&&'),
      }),
    ].join('?');
  }, [userEmployeeProfilePermissionID, $isAdmin]);

  return useSource<{
    id: string;
    title: string;
    displayNextMeetingForm: boolean;
    isForClinicalMeeting: boolean;
  }>(urlSourceNotebook);
};
export const useSourceNotebooksAll = () => {
  return useSourceNotebooks();
};
export const useSourceNotebooksForClinicalMeetings = () => {
  const result = useSourceNotebooks();

  const data = useMemo(() => {
    return result.data.filter((item) => item.isForClinicalMeeting);
  }, [result.data]);
  const map = useMap(data, 'id');

  return { ...result, data, map };
};
export const useSourceNotebooksForSupportMeetings = () => {
  const result = useSourceNotebooks();

  const data = useMemo(() => {
    return result.data.filter((item) => !item.isForClinicalMeeting);
  }, [result.data]);
  const map = useMap(data, 'id');

  return { ...result, data, map };
};

export const useSourceAppointment = (userID: string) => {
  const queryParams = useMemo(
    () =>
      generateDynamicQuery({
        select: 'meetingFromDateTime as meetingDate,userEmployeeProfile.fullName as employeeName',
        filter: [`userPatientProfileID=="${userID}"`].join('&&'),
        orderBy: 'meetingFromDateTime desc',
      }),
    [userID],
  );
  return useSource<{ employeeName: string; meetingDate: string }>(
    `${API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC}?${queryParams}`,
  );
};

export const useSourceSupportMeeting = (userID: string) => {
  const queryParams = useMemo(
    () =>
      generateDynamicQuery({
        select: 'meetingFromDateTime as meetingDate,userEmployeeProfile.fullName as employeeName',
        filter: `userPatientProfileID=="${userID}"`,
        orderBy: 'meetingFromDateTime desc',
      }),
    [userID],
  );
  return useSource<{ id: string; meetingDate: string; employeeName: string }>(
    `${API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC}?${queryParams}`,
  );
};

export const useSourcePermissions = () => {
  const queryParams = useMemo(
    () =>
      generateDynamicQuery({
        select: 'id,title,roleDietitian,roleDoctor,color',
        filter: mergeFilters(`isActive==true`).join('&&'),
        orderBy: 'title asc',
      }),
    [],
  );
  return useSource<
    Pick<
      Components.Schemas.UserEmployeeProfilePermission,
      'id' | 'title' | 'roleDietitian' | 'roleDoctor' | 'color'
    >
  >(`${API_USER_EMPLOYEE_PROFILE_PERMISSIONS.GET_ALL_DYNAMIC}?${queryParams}`, 'id', true);
};

interface SourceTodoCategory
  extends Pick<ToDoTaskCategory, 'id' | 'title' | 'defaultMessage' | 'actionKey'> {
  userEmployeeProfileIDs: string[];
}

export const useSourceTodoCategories = () => {
  const queryParams = useMemo(
    () =>
      generateDynamicQuery({
        select:
          'id,title,defaultMessage,actionKey,userEmployeeProfileToDoTaskCategories.Select(k => k.userEmployeeProfileID) as userEmployeeProfileIDs',
        filter: mergeFilters(`isActive==true`).join('&&'),
        orderBy: 'title asc',
      }),
    [],
  );
  return useSource<SourceTodoCategory>(
    `${API_TODO_TASK_CATEGORIES.GET_ALL_DYNAMIC}?${queryParams}`,
    'id',
    true,
  );
};
export const useTodoCategoryOptions = () => {
  const source = useSourceTodoCategories();
  const sourceOptions = source.data;

  const options = useMemo(() => {
    return sourceOptions.map((item) => {
      const config = CONFIG_CATEGORY_ACTION[item.actionKey];

      return {
        id: item.id,
        title: item.title,
        Icon: config?.Icon || HelpOutlineIcon,
        color: config?.color || '#000',
        userEmployeeProfileIDs: item.userEmployeeProfileIDs,
        defaultMessage: item.defaultMessage,
      };
    });
  }, [sourceOptions]);
  const map = useMap(options, 'id');

  return { options, loading: source.loading, map };
};
export const useSourceGenders = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(`isActive==true`).join('&&'),
      select: 'id,title',
      orderBy: 'title asc',
    });
  }, []);
  return useSource<{ id: string; title: string }>(
    `${API_GENDERS.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};
export const useSourceCities = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(`isActive==true`).join('&&'),
      select: 'id,title',
      orderBy: 'title asc',
    });
  }, []);
  return useSource<{ id: string; title: string }>(
    `${API_CITIES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};

export const useSourceLeadActivityTypes = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(`isActive==true`).join('&&'),
      select: 'id,title,isNewLead,isExistLead,leadActivityTypeTag',
      orderBy: 'rank',
    });
  }, []);
  return useSource<
    Pick<LeadActivityType, 'id' | 'title' | 'isNewLead' | 'isExistLead' | 'leadActivityTypeTag'>
  >(`${API_LEAD_ACTIVITY_TYPES.GET_ALL_DYNAMIC}?${query}`, 'id', true);
};

export const useSourceSubscriptions = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(`isActive==true`).join('&&'),
      select: 'id,labelKey as title',
    });
  }, []);
  return useSource(`${API_SUBSCRIPTIONS.GET_ALL_DYNAMIC}?${query}`);
};

export const useSourceWeightTypes = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select: 'id,title,rowIndex',
      filter: ['isWeight==true', 'isActive==true'].join('&&'),
    });
  }, []);
  return useSource<Pick<InputType, 'id' | 'title' | 'rowIndex'>>(
    `${API_INPUT_TYPES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};

export const useSourceMonths = () => {
  const { locale } = useDateLocale();
  const data = useMemo(
    () =>
      Array(12)
        .fill(null)
        .map((_, i) => ({
          title: locale.localize?.month(i, { width: 'abbreviated' }),
          id: String(i),
        })),
    [locale],
  );

  return { data };
};
export const useSourceYears = (options?: { start: number; end: number }) => {
  const { start, end } = useMemo(() => {
    const end = options?.end || new Date().getFullYear();
    const start = options?.end || end - 10;

    return { start, end };
  }, [options]);

  const data = useMemo(
    () =>
      Array.from({ length: end - start })
        .fill(null)
        .map((_, i) => ({
          title: String(end - i),
          id: String(end - i),
        })),
    [start, end],
  );

  return { data };
};
